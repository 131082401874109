import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { menuCode, services } from '../shared/constants/Strings';
import { existInUrl, formatPhone } from '../../util/Util';
import { URL_UPDATE_DATA } from '../../util/Constant';
import { Routes } from '../../routes';

import PSIcon from './PSIcon';
import PSBox from './PSBox';
import PSTitle from './PSTitle';
import PSLabel from './PSLabel';
import PSLoading from './PSLoading';

const StyledPSAsideBar = styled.div`
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
  align-items: center;
  padding: 0;
  font-family: BlissPro-Bold;
  background: #2b0d61;

  &.expand-bar {
    .contact-info-close {
      text-align: right !important;
    }

    .contact-info-icon {
      padding: 0 !important;
      svg {
        width: initial !important;
      }
      .a {
        fill: #ffff !important;
      }
    }

    .contact-info-header,
    .contact-info-body {
      visibility: visible !important;
    }
    .contact-info-footer {
      display: block !important;
    }

    .aside-contracted-services {
      .contracted-services-header {
        display: block !important;
      }
      .contracted-services-footer {
        display: block !important;
      }
      .contracted-services-body {
        .service-body-container {
          justify-content: initial !important;
          label {
            display: block !important;
            text-align: left;
          }
        }
      }
    }
  }
  .center-loading {
    text-align: center;
    margin-top: 40px;
  }
  .aside-contact-info {
    padding: 0 0 20px 0;
    align-items: center;
    /*background: transparent linear-gradient(90deg, #ed6e00 0%, #2b0d61 100%) 0% 0% no-repeat;*/
    background: linear-gradient(
      90deg,
      rgba(225, 104, 5, 1) 0%,
      rgba(143, 39, 39, 1) 49%,
      rgba(43, 13, 97, 1) 100%
    );
    .fix-color-icon {
      .a {
        fill: #ffff !important;
      }
    }

    .service-body-container {
      label {
        font-size: 16px;
        color: #ffffff;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .contact-info-close {
      padding: 5px 22px 15px;
      margin-bottom: 1em;
      text-align: center;
      .close-icon {
        color: #ffffff;
        font-size: 30px;
        font-family: initial;
        cursor: pointer;
        transition: all 0.5s ease;
      }
    }
    label {
      color: #ffff;
    }
    .contact-info-icon {
      transition: all 0.5s ease;
      padding: 30px 0;
      svg {
        width: 60px;
      }
    }
    .contact-info-header {
      visibility: hidden;
      .contact-info-title {
        font-size: 22px;
      }
      .contact-info-name {
        text-transform: capitalize;
        font-size: 30px;
        line-height: 25px;
        margin-bottom: 7%;
      }
    }
    .contact-info-body {
      visibility: hidden;
      font-family: 'BlissPro-Regular', sans-serif !important;
      label {
        font-size: 16px;
        line-height: 14px;
      }
      span {
        color: #ffff;
      }
      a {
        color: #ffff;
        text-decoration: underline;
      }
    }
    .contact-info-footer {
      display: none;
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-family: 'BlissPro-Bold', sans-serif;
      label {
        font-size: 16px;
        line-height: 14px;
      }
    }
  }
  .aside-contracted-services {
    background: #2b0d61;
    padding-top: 5px;
    .a {
      fill: #ed6e00;
    }
    .contracted-services-header {
      display: none;
      background-color: ${(props) => (props.index === 1 ? '#f0f0f0' : 'initial')};
      label {
        font-family: 'BlissPro-Regular', sans-serif !important;
        font-size: 18px;
        color: #ffffff;
      }
      .service-body-container-header {
        padding: 1rem;
        border-radius: ${(props) => (props.index === 1 ? '0 0 31px 0' : 'initial')};
        background-color: ${(props) => (props.index === 1 ? '#2b0d61' : 'initial')};
      }
    }

    .contracted-services-footer {
      display: none;
      background-color: ${(props) => (props.index === props.length ? '#f0f0f0' : 'initial')};
      label {
        font-family: 'BlissPro-Light', sans-serif !important;
        font-size: 15px;
        color: #ffffff;
      }
      .service-body-container-header {
        padding: 1rem;
        border-radius: ${(props) => (props.index === props.length ? '0 31px 0 0' : 'initial')};
        background-color: ${(props) => (props.index === props.length ? '#2b0d61' : 'initial')};
      }
    }
    .contracted-services-body {
      padding: 0 0 0 1rem;
      .service-body-container {
        background: #2b0d61;
        display: flex;
        padding: 1rem 1.4rem;
        justify-content: center;
        path,
        line {
          stroke: rgb(225, 109, 25);
        }
        label {
          display: none;
          &:hover {
            cursor: pointer;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      .services-body {
        background: #f0f0f0;
        width: 100%;
        justify-content: left;
        &:hover {
          cursor: pointer;
        }
        label {
          margin-top: 4px;
          font-size: 22px;
          color: #ffffff;
        }
        svg {
          height: auto;
          width: 32px;
        }
      }
    }
    ${(props) =>
      css`
        .services-body:nth-child(${props.index - 1}) {
          .service-body-container {
            border-radius: 0 0 30px 0;
          }
        }
        .services-body:nth-child(${props.index}) {
          background: #2b0d61 !important;
          .service-body-container {
            border-radius: 30px 0 0 30px;
            background: #f0f0f0;
          }
          label {
            color: #2b0d61;
          }
        }
        .services-body:nth-child(${props.index + 1}) {
          .service-body-container {
            border-radius: 0 30px 0 0;
          }
        }
      `};
  }
`;

const showUpdateData = () => {
  return process.env.REACT_APP_PERMISSION_SHOW_ACTUALIZACION_DATOS === 'true';
};
const PSAsideBar = ({
  onCloseBar,
  showProducts,
  className,
  attributesCore,
  onOpenModalUpdateData,
  onCloseModalUpdateData,
}) => {
  const history = useHistory();
  const { userInfo, isEditData } = useSelector((state) => state.auth);
  const user = userInfo ? userInfo.attributes : {};
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const [expandBar, setExpandBar] = useState(true);
  const servicesInfo = userInfo?.products || [];
  const servicesDetail = userInfo?.productsDetail || [];
  let serviceSelected = -2;
  const location = useLocation();
  const onClose = () => {
    setExpandBar(!expandBar);
    onCloseBar();
  };
  servicesDetail.forEach((s, index) => {
    if (existInUrl(services[s.product].page)) {
      serviceSelected = index + 1;
    }
  });

  const goToPage = (page, menuId, menuDescription, policies) => {
    const state = {
      menuId,
      menuDescription,
      policies,
    };
    if (location.pathname === URL_UPDATE_DATA && isEditData) {
      onOpenModalUpdateData(page, state);
    } else {
      history.push({
        pathname: page,
        state,
      });
    }
  };

  return (
    <StyledPSAsideBar
      className={className + (expandBar ? ' expand-bar' : '')}
      index={serviceSelected}
      length={servicesDetail.length}
    >
      {user ? (
        <>
          <div className="aside-contact-info">
            <div className="contact-info-close">
              {expandBar ? (
                <span className="close-icon" onClick={onClose}>
                  &times;
                </span>
              ) : (
                <PSIcon type="bar" className="close-icon" onClick={onClose} />
              )}
            </div>
            <PSBox className="contact-info-icon">
              <PSIcon type={isBusiness ? 'business' : 'user'} />
            </PSBox>
            <PSBox className="contact-info-header ">
              <PSTitle className="contact-info-title"> Hola </PSTitle>
              <PSTitle className="contact-info-name">
                {' '}
                {user
                  ? isBusiness
                    ? user.legalName.toLowerCase()
                    : user.givenName.toLowerCase()
                  : ''}{' '}
              </PSTitle>
            </PSBox>
            <PSBox className="contact-info-body">
              <PSLabel> {attributesCore ? formatPhone(attributesCore.phone) : ''} </PSLabel>
              <PSLabel> {attributesCore ? attributesCore.email : ''} </PSLabel>
              {!isBusiness && showUpdateData() && (
                <a onClick={() => history.push(Routes.HOME_UPDATE_DATA)}>Actualización de datos</a>
              )}
            </PSBox>
            <PSBox className="contact-info-footer ">
              <PSLabel> ¡Te damos la bienvenida!</PSLabel>
              <PSLabel> Conoce lo que tenemos para ti. </PSLabel>
            </PSBox>
          </div>
          {showProducts && (
            <div className="aside-contracted-services">
              <div className="contracted-services-header">
                <div className="service-body-container-header">
                  <PSLabel>Productos contratados:</PSLabel>
                </div>
              </div>
              <div className="contracted-services-body">
                {servicesDetail.map((service) => {
                  const { product, productDescription, policies } = service;
                  const useExponent = product === menuCode.VIDA_DEVOLUCION;
                  return (
                    <div
                      key={service.product}
                      className="services-body"
                      onClick={() => {
                        goToPage(services[product].page, product, productDescription, policies);
                      }}
                    >
                      <div className="service-body-container">
                        <PSIcon type={services[product].icon || 'productoRenta'} className="mr-1" />
                        <PSLabel>
                          {productDescription}
                          {useExponent && (
                            <span className="exponent" style={{ fontSize: '.8rem' }}>
                              +
                            </span>
                          )}
                        </PSLabel>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="contracted-services-footer">
                <div className="service-body-container-header" />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="center-loading">
          <PSLoading width={130} height={130} />
        </div>
      )}
    </StyledPSAsideBar>
  );
};

PSAsideBar.defaultProps = {
  onCloseBar: () => {},
  showProducts: true,
  className: '',
  attributesCore: null,
  onOpenModalUpdateData: () => {},
  onCloseModalUpdateData: () => {},
};

PSAsideBar.propTypes = {
  onCloseBar: PropTypes.func,
  showProducts: PropTypes.bool,
  className: PropTypes.string,
  attributesCore: PropTypes.object,
  onOpenModalUpdateData: PropTypes.func,
};

export default PSAsideBar;
