import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import PSNewsletterItem from './PSNewsletterItem';

const LIMIT_CARDS = 6;
const RANGE_PER_PAGES = 8;
const SHOW_OLD_VERSION = false;
const StyledPSItemNewsletter = styled.div`
  .newsletter-container-items {
    display: flex;
    width: 72%;
    flex-wrap: wrap;
    margin: 1rem auto;
    justify-content: center;
    .newsletter-items {
      width: 30%;
      margin: 1rem;
    }
  }
  .pagination {
    width: 650px;
    margin: 0 auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 5%;
    li:not(.previous, .next, .break-me) {
      margin: 0.4rem;
      background: #2b0d61;
      color: #fff;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      text-align: center;
      font-size: 20px;
      a {
        outline: none;
        color: white;
        padding: 5px;
      }
    }
    .previous,
    .next,
    .break-me {
      a {
        font-weight: bold;
        font-size: 1.5rem;
        color: #2b0d61;
        outline: none;
      }
    }
    .active {
      background: #ed6e00 !important;
    }
  }
  .container-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 5%;
    .item-pagination {
      margin: 0.4rem;
      background: #2b0d61;
      color: #fff;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      text-align: center;
      font-size: 20px;
    }
    .item-current {
      background: #ed6e00;
    }
  }

  @media (max-width: 576px) {
    .pagination {
      width: 100% !important;
    }
    .newsletter-container-items {
      width: 100%;
      .newsletter-items {
        width: 45%;
        margin: 0.5rem;
      }
    }
  }
`;

const PSNewsletterContainerItem = React.forwardRef(
  (
    {
      options,
      showDetail,
      nroPagination,
      currentPagination,
      setCurrentPagination,
      showPagination,
      ...props
    },
    ref
  ) => {
    return (
      <StyledPSItemNewsletter {...props} ref={ref}>
        <div className="newsletter-container-items">
          {options?.map((item, index) => {
            if (!item) return
            return (
              <PSNewsletterItem
                magazine={item}
                key={index}
                className="newsletter-items"
                onClick={showDetail}
              />
           );
          })}
        </div>
        {showPagination && (
          <div>
            <ReactPaginate
              pageCount={nroPagination.length}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakClassName="break-me"
              marginPagesDisplayed={2}
              initialPage={currentPagination - 1}
              onPageChange={(page) => setCurrentPagination(page.selected + 1)}
              pageRangeDisplayed={RANGE_PER_PAGES}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        )}
        {SHOW_OLD_VERSION && (
          <div className="container-pagination">
            {showPagination &&
              nroPagination.map((itemPagintation, index) => (
                <div
                  key={index}
                  className={`item-pagination ${
                    currentPagination === index + 1 ? 'item-current' : ''
                  }`}
                  onClick={() => setCurrentPagination(index + 1)}
                >
                  {index + 1}
                </div>
              ))}
          </div>
        )}
      </StyledPSItemNewsletter>
    );
  }
);

PSNewsletterContainerItem.defaultProps = {
  showPagination: true,
  showDetail: () => {},
};

PSNewsletterContainerItem.propTypes = {
  showPagination: PropTypes.bool,
  showDetail: PropTypes.func,
};

export default PSNewsletterContainerItem;
