import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import DevolutionLyfe from '../../../shared/services/DevolutionLyfe';
import { createErrorPage } from '../../../redux/auth';
import { GAProvider } from '../../PSAnalytics';

import PSDynamicTable from '../../PSDynamicTable';
import PSLoading from '../../PSLoading';

import './index.scss';

const headerTable = [
  { elementProp: 'card', headerColumn: 'Número de tarjeta' },
  { elementProp: 'type', headerColumn: 'Tipo de tarjeta' },
  // { elementProp: 'codComercio', headerColumn: 'Cod. Comercio' },
  { elementProp: 'lastUpdate', headerColumn: 'Fecha de cambio de tarjeta' },
];

const PSHistoryCard = React.forwardRef(({ poliza, className, onCloseOption }, ref) => {
  const ga = React.useContext(GAProvider);
  const { userInfo, stateProduct = {} } = useSelector((state) => state.auth);
  const { menuDescription = '' } = stateProduct;
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const dispatch = useDispatch();

  const loadData = async () => {
    try {
      setLoading(true);
      let historyResponse = await DevolutionLyfe.getHistoryChangeCard(poliza.id);
      if (historyResponse) {
        if (historyResponse.length > 0) {
          historyResponse = historyResponse.map((h) => ({
            ...h,
            type: h.type || '-',
            id: h.lastUpdate,
          }));
          setHistory(historyResponse);
          const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
          ga.pageview(window.location.pathname + window.location.search);
          ga.event({
            category: `${messageTypeUser}Producto ${menuDescription}-Historial de Cambio de Tarjetas`,
            action: 'click',
            label: 'El usuario visualizó su Historial de Cambio de Tarjetas',
            value: 1,
          });
        } else
          dispatch(
            createErrorPage({
              state: true,
              message: 'No se encontraron cambios de tarjeta asociado a la póliza',
              onAction: () => onCloseOption(),
              icon: 'face',
              title: '',
            })
          );
      } else {
        dispatch(
          createErrorPage({
            state: true,
            message: 'No se encontró una tarjeta asociada a la póliza',
            onAction: () => onCloseOption(),
            icon: 'face',
            title: 'Error',
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        createErrorPage({
          state: true,
          message: 'Error al consultar Historial de Cambio de tarjetas para cobro recurrente',
          onAction: () => onCloseOption(),
          icon: 'face',
          title: 'Error',
        })
      );
    }
  };

  useEffect(() => {
    loadData();
  }, [poliza]);

  return (
    <div className={`container-historyCard ${className}`} ref={ref}>
      {loading ? (
        <div className="loading">
          <PSLoading />
        </div>
      ) : (
        <div>{history.length > 0 && <PSDynamicTable headers={headerTable} items={history} />}</div>
      )}
    </div>
  );
});

PSHistoryCard.displayName = 'PSHistoryCard';

PSHistoryCard.defaultProps = {
  className: '',
  poliza: null,
  onCloseOption: null,
};

PSHistoryCard.propTypes = {
  className: PropTypes.string,
  poliza: PropTypes.object,
  onCloseOption: PropTypes.func,
};

export default PSHistoryCard;
