import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSIcon from './PSIcon';
import PSLoading from './PSLoading';

const StyledPSLoginContainer = styled.div`
  background: #2b0d61;
  width: 375px;
  height: 650px;
  padding: 1rem 1.2rem 4rem 1.2rem;
  display: block;
  align-items: initial;
`;

const StyledPsHeaderIcon = styled.div`
  width: 100%;
  border: none;
  height: initial;
  display: block;
  align-items: initial;
  text-align: right;
  cursor: pointer;
  svg {
    height: 16px;
    width: 16px;
  }
  
`;

const PSLoginContainer = React.forwardRef(
  ({ children, className, onClose, showCloseIcon, loading }, ref) => (
    <StyledPSLoginContainer className={className} ref={ref}>
      {showCloseIcon && !loading && (
        <StyledPsHeaderIcon>
          {!loading && <PSIcon type="close" onClick={onClose} />}
        </StyledPsHeaderIcon>
      )}
      <div className="container-children">{children}</div>
    </StyledPSLoginContainer>
  )
);

PSLoginContainer.displayName = 'PSLoginContainer';

PSLoginContainer.defaultProps = {
  className: null,
  onClose: () => {},
  showCloseIcon: true,
  loading: false,
};

PSLoginContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PSLoginContainer;
