import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createErrorPage } from '../../redux/auth';

import { fixStructBase64, getOptionsToProduct, dateNowFormatted } from '../../../util/Util';
import { policyFilterMessage } from '../../shared/constants/Strings';
import { Coverage } from '../../shared/constants/Coverage';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import GenericConstancyServices from '../../shared/services/GenericConstancyServices';
import GetCertificates from '../../shared/services/GetCertificates';

import { GAProvider } from '../../components/PSAnalytics';
import PSBodyProduct from '../../components/PSBodyProduct';

import './index.scss';

const img = 'product-VidaLey';
const imgMobile = 'product-VidaLey-mobile';

const headerTableFather = [
  { elementProp: 'shortDescription', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

export default function HomeProductVidaLey(props) {
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const [options, setOptions] = useState([]);
  const isBusiness = userInfo?.isBusiness || false;
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCode, setCurrentCode] = useState(null);
  const [fileBase64, setFileBase64] = useState({});
  const location = useLocation();
  const { policies = [] } = location?.state;
  const menuId =
    location.state?.menuId || location.pathname.includes('vidaLey') ? '20000004' : undefined;
  const policiesMessage = policyFilterMessage.vidaLey;

  const getOptionsAvailable = (rol = 'Asegurado') => {
    const option = [];

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_LEY_CONSTANCIA_ASEGURADO === 'true' &&
      !isBusiness &&
      rol.trim() !== 'Contratante'
    ) {
      option.push('CDA');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_ASEGURADOS === 'true' && isBusiness) {
      option.push('CDLA');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_ASEGURADOS === 'true' && isBusiness) {
      option.push('CICA');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_LEY_DESCARGA_POLIZA === 'true') {
      option.push('DP');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_LEY_DESCARGA_COMPROBANTE_PAGO === 'true') {
      option.push('DCP');
    }
    setOptions(option);
  };

  const getComprobantePago = () => {
    if (currentIndex !== -1) {
      const { nbranch, idProduct, nroPolicy, nroCertificate } = policies[currentIndex];
      const data = {
        'branch-id': parseInt(nbranch),
        'product-id': parseInt(idProduct),
        policy: nroPolicy,
        certificate: nroCertificate,
      };
      setParamsComprobantes(data);
    }
  };

  const getConstanciaGenerica = async (policyCurrent) => {
    try {
      setLoadingOption(true);

      if (currentIndex !== -1) {
        const responseAsegurados = await GetCertificates.responseListaAsegurados(policyCurrent);
        let { data, statusCode } = responseAsegurados;
        data = data?.listaAsegurados;
        const listAsegurados = data.map((l) => {
          const numberDocument = l.documento.split('-');
          return {
            document: numberDocument[1].trim(),
            fullName: l?.nombre,
            birthday: l?.fechaNacimiento,
          };
        });

        if (statusCode === 200) {
          const user = userInfo.attributes;
          if (listAsegurados.length > 0) {
            const data = {
              data: {
                beneficiaries: listAsegurados,
                titular: user ? (isBusiness ? user?.legalName : user?.givenName) : '',
                policy: policyCurrent?.nroPolicy,
                initialDate: policyCurrent?.dateStart,
                endDate: policyCurrent?.dateEnd,
                sendEmail: false,
              },
            };

            const response = await GenericConstancyServices.responseDocument(data);
            if (response.status === 200) {
              const fixBase64 = fixStructBase64(response.data.document);
              setFileBase64.DCG = fixBase64;
            } else {
              setFileBase64.DCG = null;
            }
          } else {
            setTimeout(() => {
              alert('No se encontraron asegurados');
            }, 1000);
          }
        }
      }
    } catch (error) {
      setFileBase64.DCG = null;
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };
  const getConstanciaUserNatural = async (policyCurrent) => {
    try {
      setLoadingOption(true);
      const { attributes: user, documentType, document: documentNumber } = userInfo;
      const dateFormatted = dateNowFormatted();
      const params = {
        data: {
          arrayBoletas: [
            {
              documentType,
              clientName: user?.legalName || '',
              documentNumber,
              initValidity: policyCurrent?.dateStart,
              finishValidity: policyCurrent?.dateEnd,
              policy: policyCurrent?.nroPolicy.toString().padStart(10, '0'),
              policyType: 'Seguro de Vida Ley',
              ammount: 'Según condiciones de la Póliza',
              dateDescription: `Surquillo, ${dateFormatted}`,
            },
          ],
          enviarEmail: false,
        },
      };
      const headers = { 'segment-id': menuId };
      const { data, status } = await GenericConstancyServices.responseDocumentUserNatural(
        params,
        headers
      );

      if (data && status === 200) {
        const fixBase64 = fixStructBase64(data.document);
        setFileBase64.CDA = fixBase64;
      } else {
        setFileBase64.CDA = null;
      }
    } catch (error) {
      setFileBase64.CDA = null;
      dispatch(createErrorPage());
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };

  
  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];
      getOptionsAvailable(policyCurrent.rol);
      if (currentCode === 'CDA') {
        getConstanciaUserNatural(policyCurrent);
      }
      if (isBusiness && (currentCode === 'CDLA' || currentCode === 'CICA')) {
        getConstanciaGenerica(policyCurrent);
      }
      if (currentCode === 'DCP') {
        getComprobantePago();
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto Vida Ley`,
      action: 'click',
      label: 'El usuario visitó la página de Vida Ley',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="home-vidaLey-container">
      <PSBodyProduct
        questions={FrequentQuestions.VIDA_LEY}
        coverages={Coverage.VIDA_LEY}
        paramsComprobantes={paramsComprobantes}
        img={img}
        imgMobile={imgMobile}
        descriptionHeader="Vida Ley"
        iconHeader="bag"
        options={getOptionsToProduct(options)}
        fileBase64={fileBase64}
        loading={loading}
        data={policies}
        headerTable={headerTableFather}
        textButtonToProduct="¡Miralos aquí!"
        withLinkToProduct
        onSelectItem={(item) => setCurrentIndex(item)}
        onSetCurrentCodeForm={setCurrentCode}
        menuId={menuId}
        loadingOption={loadingOption}
        useDigitalPolicy
        policiesMessage={policiesMessage}
        {...props}
      />
    </div>
  );
}
