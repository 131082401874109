import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { pathServer, STORAGE_TOKEN_COGNITO, URL_UPDATE_DATA } from '../../util/Constant';
import { Routes } from '../../routes';
import { cleanAuth } from '../redux/auth';
import { GAProvider } from './PSAnalytics';

import PSLabel from './PSLabel';
import PSButton from './PSButton';
import { showMenuCoupon } from '../../util/Util';
import { logout } from '../redux/auth/actions/user-login';

const StyledPSHeaderPageHome = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  .option-selected {
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-width: 1.5px;
  }
  .option-selected-left {
    border-left: 1px solid #e4e4e4;
    border-width: 1.5px;
  }
  .container-img {
    text-align: center;
  }
  .header-bar:hover {
    cursor: pointer;
  }
  .section-header {
    padding: 0.8rem;
    width: 100%;
    display: flex;
    img {
      height: auto;
      width: 80%;
      &:hover {
        cursor: pointer;
      }
    }
    span {
      margin: 0 auto;
    }
    label {
      letter-spacing: 0px;
      color: #7a7a7a;
      opacity: 1;
      font-family: 'BlissPro-Bold';
      font-size: 15px;
    }
    .container-nav {
      padding: 0.75rem;
      .container-dropdown {
        background: #ffff;
        top: 60%;
        left: 42px;
        min-width: 75%;
        padding: 0;
        a {
          font-family: 'BlissPro-ExtraBold', sans-serif !important;
          border: 0.5px solid #dbdbdb;
          border-top: none;
          font-weight: 600;
          padding: 15px 0;
          color: #ed6e00;
          font-size: 15px;
        }
        .item-menu:hover {
          background-color: #ed6e00 !important;
          color: #ffff !important;
        }
      }
    }
    .container-button {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      button {
        font-size: 15px;
        // width: 55%;
        height: 36px;
      }
    }
  }
`;

const PSHeaderPageHome = React.forwardRef(
  ({ hasTitle, className, onOpenModalUpdateData, disabledSignOut }, ref) => {
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();
    const { userInfo, isEditData } = useSelector((state) => state.auth);
    const ga = React.useContext(GAProvider);
    const isBusiness = userInfo ? userInfo.isBusiness : false;
    const servicesDetail = userInfo?.productsDetail || [];
    const issuesDateSoat = userInfo?.issuesDateSoat || [];
    const hasCoupons = process.env.REACT_APP_SHOW_COUPONS === 'true';
    const [soatVigency, setSoatVigency] = useState(false);
    const banners = useSelector((state) => state.auth).banners || {
      promotions: [],
    };
    const signAuth = () => {
      dispatch(cleanAuth());
      dispatch(logout())
      localStorage.removeItem(STORAGE_TOKEN_COGNITO);
      localStorage.clear();
      history.push(Routes.LOGIN);
    };

    const goToPage = (page, state) => {
      if (location.pathname === URL_UPDATE_DATA && isEditData) {
        onOpenModalUpdateData(page, state);
      } else {
        history.push({ pathname: page, state });
      }
    };

    const goToNewSletter = () => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Newsletter`,
        action: 'click',
        label: 'El usuario visitó la página principal de Newsletter',
        value: 1,
      });
      goToPage(Routes.HOME_NEWSLETTER);
    };

    useEffect(() => {
      setSoatVigency(
        showMenuCoupon(servicesDetail, banners.promotions, issuesDateSoat, userInfo.showPromoFree)
      );
    }, [servicesDetail, banners.promotions, issuesDateSoat, userInfo.showPromoFree]);

    return (
      <StyledPSHeaderPageHome ref={ref} className={className}>
        <div className="section-header columns">
          <div className="column is-one-quarter container-img">
            <img
              src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`}
              onClick={() => {
                if (hasTitle) goToPage(Routes.HOME);
              }}
            />
          </div>
          {hasTitle ? (
            <>
              {process.env.REACT_APP_AVAILABLE_OPTION_TRAMITES === 'true' && (
                <div className="column mt-1 navbar-item has-dropdown is-hoverable container-nav">
                  <PSLabel
                    className="header-bar option-bloqued"
                    data-place="bottom"
                    data-tip={
                      process.env.REACT_APP_AVAILABLE_OPTION_TRAMITES !== 'true'
                        ? 'Estamos trabajando para que tus trámites estén al alcance de un clic'
                        : null
                    }
                  >
                    Trámites
                  </PSLabel>
                  {process.env.REACT_APP_AVAILABLE_OPTION_TRAMITES === 'true' && (
                    <div className="navbar-dropdown container-dropdown ">
                      {/* <a className="navbar-item item-menu">
                        <span>Ingreso de solicitudes</span>
                      </a> */}
                      <a
                        className="navbar-item item-menu"
                        onClick={() => goToPage(Routes.HOME_REGISTER_CLAIM)}
                      >
                        <span>Ingreso de reclamos</span>
                      </a>
                    </div>
                  )}
                </div>
              )}

              <div className="column mt-1 navbar-item has-dropdown is-hoverable container-nav">
                <PSLabel className="header-bar option-selected">Consultas</PSLabel>
                <div className="navbar-dropdown container-dropdown ">
                  <a
                    className="navbar-item item-menu"
                    onClick={() => goToPage(Routes.HOME_STATE_REQUEST)}
                  >
                    <span>Estado de solicitudes</span>
                  </a>
                  <a
                    className="navbar-item item-menu"
                    onClick={() => goToPage(Routes.HOME_STATE_PROCEDURE)}
                  >
                    <span>Estado de trámites</span>
                  </a>
                  <a
                    className="navbar-item item-menu"
                    onClick={() => goToPage(Routes.HOME_STATE_CLAIM)}
                  >
                    <span>Estado de reclamos</span>
                  </a>
                  <a
                    className="navbar-item item-menu"
                    onClick={() => goToPage(Routes.HOME_FREQUENT_QUESTION)}
                  >
                    <span>Preguntas Frecuentes</span>
                  </a>
                </div>
              </div>
              {!isBusiness && (
                <div className="column mt-1" onClick={() => goToPage(Routes.HOME_REWARDS)}>
                  <PSLabel className="header-bar">Club Protecta Security</PSLabel>
                </div>
              )}
              {process.env.REACT_APP_PERMISSION_NEWSLATTERS === 'true' && (
                <div className="column mt-1" onClick={() => goToNewSletter()}>
                  <PSLabel className={`header-bar ${!isBusiness ? 'option-selected-left' : ''}`}>
                    Newsletter
                  </PSLabel>
                </div>
              )}
              {soatVigency && hasCoupons && (
                <div className="column mt-1" onClick={() => goToPage(Routes.HOME_COUPONS)}>
                  <PSLabel className="header-bar option-selected-left">Promo gratis</PSLabel>
                </div>
              )}
              <div className="column container-button">
                <PSButton disabled={disabledSignOut} onClick={signAuth}>
                  Cerrar sesión
                </PSButton>
              </div>
            </>
          ) : null}
        </div>
        {process.env.REACT_APP_AVAILABLE_OPTION_TRAMITES !== 'true' && (
          <ReactTooltip place="bottom" type="dark" />
        )}
      </StyledPSHeaderPageHome>
    );
  }
);

PSHeaderPageHome.defaultProps = {
  className: null,
  hasTitle: false,
  onOpenModalUpdateData: () => {},
};

PSHeaderPageHome.propTypes = {
  className: PropTypes.string,
  hasTitle: PropTypes.bool,
  onOpenModalUpdateData: PropTypes.func,
};

export default PSHeaderPageHome;
