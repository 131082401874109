import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {optionsHeaderNewSletter, pathServer, STORAGE_TOKEN_COGNITO} from '../../util/Constant';
import PSIcon from './PSIcon';

import { Routes } from '../../routes';
import { cleanAuth } from '../redux/auth';
import PSAsideBar from './PSAsideBar';
import ReactTooltip from "react-tooltip";
import { logout } from '../redux/auth/actions/user-login';

const StyledPSNavBar = styled.nav`
  .navbar-item {
    text-align: center;
    img {
      height: 48px;
      max-height: max-content;
    }
  }
  .header-icon-user {
    vertical-align: unset;
    align-items: self-start;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 18px;
    padding: 0;
    margin-right: 0;
    svg {
      width: 1.75rem;
      height: auto;
      .a {
        fill: #2b0d61 !important;
      }
    }
  }

  .header-selected {
    vertical-align: unset;
    align-items: self-start;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 18px;
    padding: 0;
    margin-right: 0;
    svg {
      width: 1.75rem;
      height: auto;
      .a {
        fill: #ed6e00 !important;
      }
    }
  }
  .subItem:hover {
    color: white !important;
    background: #ed6e00;
  }
  .navbar-start {
    .navbar-item {
      padding: 18px 0;
      border: 0.5px solid #dbdbdb;
      font-weight: 600;
      padding: 15px 0;
      font-family: 'BlissPro-ExtraBold', sans-serif !important;
      color: white;
      font-size: 15px;
      background: #ed6e00;
    }
    .signAuth {
      background: #2b0d61;
      font-family: 'BlissPro-ExtraBold', sans-serif !important;
      a {
        color: #fff;
      }
    }
  }

  .has-dropdown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }

  .product-header {
    color: #2b255b !important;
  }
  .product {
    color: #2b255b !important;
    &:hover {
      background: #2b255b9c !important;
      color: white !important;
    }
  }

  .navbar-burger {
    height: initial;
    background-color: transparent;
  }

  .navbar-burger span {
    background-color: #ed6e00 !important;
  }

  .navbar-link {
    background: #2b255b;
    color: white;
    padding: 10px 0;
  }

  .navbar-dropdown {
    padding-top: 0;
  }

  .navbar-end {
    display: flex;
    justify-content: center;
    .button {
      color: white;
      background: #ed6e00 !important;
    }
  }
  .icon-arrow {
    display: inline-block;
    .d {
      fill: #808080;
    }

    svg {
      g {
        path {
          fill: #808080;
        }
      }
    }
  }
  .asidebar-mobile {
    position: absolute;
    .aside-contact-info {
      padding-bottom: 4rem;
      min-height: 100vh;
      .contact-info-icon {
        margin-top: 15vh;
      }
    }
  }
`;

const openNavBar = (e, closeAvatar) => {
  e.preventDefault();
  document.querySelector('.navbar-menu').classList.toggle('is-active');
  document.querySelector('.navbar-burger').classList.toggle('is-active');
  closeAvatar();
};

const closeNavBar = () => {
  document.querySelector('.navbar-menu').classList.remove('is-active');
  document.querySelector('.navbar-burger').classList.remove('is-active');
};
const PSNavBar = React.forwardRef(({ openAsideBar, closeAsideBar, ...props }, ref) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [showAvatar, setShowAvatar] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const signAuth = () => {
    dispatch(logout())
    dispatch(cleanAuth());
    localStorage.removeItem(STORAGE_TOKEN_COGNITO);
    history.push(Routes.LOGIN);
  };
  return (
    <StyledPSNavBar
      {...props}
      showAvatar={showAvatar}
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      ref={ref}
    >
      <div className="navbar-brand ">
        <a className="navbar-item" href="#">
          <img
            src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`}
            onClick={() => {
              history.push(Routes.HOME);
            }}
          />
        </a>
        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={(e) => openNavBar(e, () => setShowAvatar(false))}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          {optionsHeaderNewSletter.map((op,index) => (
            <a key={index} className="navbar-item" onClick={() => history.push(op.link)}>
              {op.description}
            </a>
          ))}
          <div className="navbar-item signAuth">
            <a onClick={signAuth}>Cerrar sesión</a>
          </div>
        </div>
      </div>
      {showAvatar && (
        <PSAsideBar
          showProducts={false}
          onCloseBar={() => {
            setShowAvatar(false)
            closeAsideBar();
          }}
          className="asidebar-mobile"
        />
      )}
    </StyledPSNavBar>
  );
});

PSNavBar.defaultProps = {
  openAsideBar: () => {},
  closeAsideBar: () => {},
};

PSNavBar.propTypes = {
  openAsideBar: PropTypes.func,
  closeAsideBar: PropTypes.func,
};

export default PSNavBar;
