import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { pathServer } from '../../../util/Constant';
import { getTypeAdvert, isEmpty, mapToSelect } from '../../../util/Util';
import { formInitial } from '../HomeUpdateData/constants';
import doInitQueryQL from '../../shared/services/GraphqlLocations';
import ClientsService from '../../shared/services/ClientsService';
import { Routes } from '../../../routes';
import { Customer } from '../../shared/model/Customer';
import { loadDirections } from '../../redux/auth/actions/load-address';
import { PSModalBelcorp } from '../../components/organisms';
import PSLabel from '../../components/PSLabel';
import PSGrayBoxContainer from '../../components/PSGrayBoxContainer';
import PSModalAdvertisements from '../../components/PSModalAdvertisements';
import PSCarousel from '../../components/PSCarousel';
import PSButton from '../../components/PSButton';
import StarSVG from '../../../assets/svg/star';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';

const services = [
  {
    description: 'Preguntas frecuentes',
    icon: 'serviceQuestion',
    link: Routes.HOME_FREQUENT_QUESTION,
  },
];

export default function HomeServices({ isMobile }) {
  const history = useHistory();
  const ga = React.useContext(GAProvider);
  const { userInfo, attributesCore } = useSelector((state) => state.auth);
  const banners = useSelector((state) => state.auth).banners || {
    opportunityOfMonth: [],
    pqp: [],
    promotions: [],
  };
  const servicesDetail = userInfo?.productsDetail || [];
  const [typAdverts, setTypAdverts] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showModalBelcorp, setShowModalBelcorp] = useState(false);
  const [setLocationQL, dataQL] = useLazyQuery(doInitQueryQL.getQuery(), {
    variables: { department: 12, province: 1401 },
  });
  const [formLocation, setFormLocation] = useState([...formInitial]);
  const dataGraphQL = dataQL.data;
  const dispatch = useDispatch();
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const linkDefault = `http://www.clubprotectasecurity.pe/`;
  const urlWebOpportunity =
    banners.opportunityOfMonth.length > 0
      ? banners.opportunityOfMonth[0].image.urlWeb
      : `${pathServer.PATH_ICONS}Home_service.png`;
  const urlMobileOpportunity =
    banners.opportunityOfMonth.length > 0
      ? banners.opportunityOfMonth[0].image.urlMobile
      : `${pathServer.PATH_ICONS}Home_service.png`;
  const linkOpportunity =
    banners.opportunityOfMonth.length > 0 ? banners.opportunityOfMonth[0].link : linkDefault;

  const urlWebPqp =
    banners.pqp.length > 0
      ? banners.pqp[0].image.urlWeb
      : `${pathServer.PATH_ICONS}proteccion_PS.png`;
  const urlMobilePqp =
    banners.pqp.length > 0
      ? banners.pqp[0].image.urlMobile
      : `${pathServer.PATH_ICONS}proteccion_PS.png`;
  const linkPQP = banners.pqp.length > 0 ? banners.pqp[0].link : linkDefault;

  const onLoadCustomerInfo = async () => {
    try {
      const response = await ClientsService.responseGetClients();
      if (response?.statusCode === 200) {
        const customer = new Customer(response.data[0]);
        const form = formInitial;
        if (customer.address.length > 0) {
          const address = customer.address[0];
          form[0].value = address.P_NCOUNTRY;
          form[1].value = address.P_NPROVINCE;
          form[2].value = address.P_NLOCAL;
          form[3].value = address.P_NMUNICIPALITY;
          form[4].value = address.P_SRECTYPE;
          form[5].value = address.P_STI_DIRE;
          form[6].value = address.P_SNUM_DIRECCION;
          form[7].value = address.P_SNOM_DIRECCION;
          form[8].value = address.P_STI_INTERIOR;
          form[9].value = address.P_SNUM_INTERIOR;
          form[10].value = address.P_SREFERENCE;
          if (address.P_STI_INTERIOR) {
            form[9].disabled = true;
          }
          setFormLocation([...form]);
          loadDepartments(parseInt(address.P_NPROVINCE, 10), parseInt(address.P_NLOCAL, 10));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setLoadingSaveForm(false);
    }
  };

  // todo pasar a custom hook
  const loadDepartments = (department, province) => {
    setLocationQL({ variables: { department, province } });
    if (dataGraphQL) {
      formLocation[1].options = mapToSelect(dataGraphQL.departments.items, 'id', 'description');
      formLocation[2].options = mapToSelect(dataGraphQL.provinces.items, 'id', 'description');
      formLocation[3].options = mapToSelect(dataGraphQL.districts.items, 'id', 'description');
    }
  };

  useEffect(() => {
    onLoadCustomerInfo();
  }, []);

  useEffect(() => {
    if (dataGraphQL) {
      formLocation[1].options = mapToSelect(dataGraphQL.departments.items, 'id', 'description');
      formLocation[2].options = mapToSelect(dataGraphQL.provinces.items, 'id', 'description');
      formLocation[3].options = mapToSelect(dataGraphQL.districts.items, 'id', 'description');
      dispatch(loadDirections([...formLocation]));
    }
  }, [dataGraphQL]);

  useEffect(() => {
    const advert = getTypeAdvert(
      servicesDetail,
      banners.promotions,
      userInfo?.issuesDateSoat || []
    );
    if (!isEmpty(advert)) {
      setTypAdverts(advert);
    }
  }, [servicesDetail, userInfo.issuesDateSoat]);

  useEffect(() => {
    if (attributesCore) {
      if (attributesCore.belcorpType) {
        setShowModalBelcorp(true);
      }
    }
  }, [attributesCore]);

  return (
    <div className="home-service-container">
      <div className="section-titulo">
        <PSLabel className="titulo">Oportunidad del mes</PSLabel>
      </div>
      <div className="columns img-opportunity">
        <div className="column is-full">
          <img
            src={isMobile ? urlMobileOpportunity : urlWebOpportunity}
            className="img mt-1 w-100 cursor-pointer "
            alt="Oportunidad del mes"
            onClick={() => {
              window.open(linkOpportunity);
              const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
              ga.pageview(window.location.pathname + window.location.search);
              ga.event({
                category: `${messageTypeUser}Oportunidad del mes`,
                action: 'click',
                label: 'El usuario entro al link de redireccionamiento de Oportunidad del mes',
                value: 1,
              });
            }}
          />
        </div>
      </div>
      <div className="section-titulo mt-1">
        <PSLabel className="titulo">Más consultados</PSLabel>
      </div>
      <div className="columns mt-1 display-none-mobile">
        {services.map((service) => (
          <PSGrayBoxContainer
            key={service.description}
            description={service.description}
            icon={service.icon}
            className="column is-one-third box-container-home"
            onClick={() => history.push(service.link)}
          />
        ))}
      </div>

      <div className="columns mt-1 display-only-mobile">
        <PSCarousel typeIcon="secondary">
          {services.map((service) => (
            <PSGrayBoxContainer
              hasChangeBorder
              key={service.description}
              description={service.description}
              icon={service.icon}
              className="column box-container-home"
              onClick={() => history.push(service.link)}
            />
          ))}
        </PSCarousel>
      </div>

      {!isBusiness && (
        <>
          <div className="section-titulo mt-1">
            <PSLabel className="titulo">Club Protecta Security</PSLabel>
          </div>
          <div className="columns img-rewards">
            {/* <div className="column is-full" onClick={() => history.push(Routes.HOME_REWARDS)}> */}
            <div
              className="column is-full"
            >
              <div
                className="container img-rewards"
                style={{
                    backgroundImage: `url(${isMobile ? urlMobilePqp : urlWebPqp})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    borderRadius: '20px',
                }}
              >
                <div className='container__promotic'>
                  <div className="title">
                    <StarSVG/>              
                  </div>       
                  <PSButton  onClick={() => {
                    window.open(linkPQP);
                    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
                    ga.pageview(window.location.pathname + window.location.search);
                    ga.event({
                        category: `${messageTypeUser}Promociones y Comunicados`,
                        action: 'click',
                        label:
                          'El usuario entro al link de redireccionamiento de Promociones y Comunicados',
                        value: 1,
                      });
                    }}
                    className="button__orange" >Descúbrelo aquí</PSButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showModal && typAdverts.length > 0 && (
        <PSModalAdvertisements
          isMobile={isMobile}
          types={typAdverts}
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}

      {showModalBelcorp && (
        <PSModalBelcorp
          isBusiness={isBusiness}
          type={attributesCore.belcorpType}
          openModal={showModalBelcorp}
          onClose={() => {
            setShowModalBelcorp(false);
          }}
        />
      )}
    </div>
  );
}
