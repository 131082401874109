import React, { useState, useMemo, useEffect } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import ReactTooltip from 'react-tooltip';
import { GAProvider } from './PSAnalytics';

import { HeaderTableBeneficiary, services, userDefault } from '../../util/Constant';
import { findValueByField, getCodeForm, isEmpty, capitalizeSentence } from '../../util/Util';
import FormsService from '../shared/services/FormsService';
import { CodeOptProduct, DescriptionForm, menuCode } from '../shared/constants/Strings';

import PSMessageResponse from './PSMessageResponse';
import PSLabel from './PSLabel';
import PSHeaderIntranet from './PSHeaderIntranet';
import PSLoading from './PSLoading';
import PSDynamicTable from './PSDynamicTable';
import PSIcon from './PSIcon';
import PSGrayBoxContainer from './PSGrayBoxContainer';
import PSQuestion from './PSQuestion';
import PSCoverage from './PSCoverage';
import PSCarousel from './PSCarousel';
import PSComponentForm from './PSComponentForm';
import PSPreviewFile from './PSPreviewFile';
import PSGeolocation from './PSGeolocation';
import PSPreviewFileHtml from './PSPreviewFileHTML';
import PSOptionsBodyProduct from './PSOptionsBodyProduct';
import PSOptionRegisterPolicy from './PSOptionRegisterPolicy';
import PSList from './PSList';
import PSButton from './PSButton';
import PSTableIndividualAsegurado from './molecules/PSTable/PSTable';
import PSTableComprobantes from './molecules/PSTableComprobantes/PSTableComprobantes';
import {
  PSConstanciaAseguradoSeguro,
  PSFormChangeBeneficiary,
  PSFormChangeBeneficiaryBurial,
  PSModalErrorResponse,
  PSModalSuccess,
} from './organisms';
import PSCertificadoDigital from './organisms/PSCertificadoDigital';
import PSFormChangePayment from './organisms/PSFormChangePayment';
import PSUpdateCard from './templates/PSUpdateCard/PSUpdateCard';
import PSHistoryCard from './templates/PSHistoryCard/index';
import OTPValidation from '../pages/OTPValidation';
import PSExpiredCoupons from './templates/PSExpiredCoupons';

const StyledPsBodyProduct = styled.div`
  background: #f0f0f0 0% 0% no-repeat padding-box;
  height: 100%;
  width: 100%;

  .header {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 170px;
    }
    svg {
      width: 54px;
      height: auto;
      padding-top: 15px;
      margin-right: 10px;
    }
    .title-header {
      font-size: ${(props) => (props.isDifferentPage ? '30px' : '64px')};
    }
  }

  .body-different-page {
    padding: 4rem 6.5rem 4rem 4.5rem;
    .container__buttons-form {
      display: flex;
      justify-content: space-around;
      margin: 1rem;
      button {
        width: 35%;
      }
    }
  }
  .body-optionRegister {
    display: flex;
    height: calc(100% - 200px);
  }
  .body {
    padding: 2.25rem 1.75rem;
    .container-body-section {
      margin: 1rem 3rem;
    }
    .button-line {
      outline: none;
      text-align: left;
      color: #2b0d61 !important;
      font-size: 16px !important;
      font-weight: 600;
      font-family: 'BlissPro-ExtraBold', sans-serif !important;
      width: 100% !important;
    }

    .titulo {
      font-family: 'BlissPro-Heavy', sans-serif !important;
      text-align: left;
      letter-spacing: 0px;
      color: #1b1944;
      opacity: 1;
      font-size: 20px;
      font-weight: 700;
    }

    .titulo::after {
      content: '';
      border-bottom: #1b1944 0.2rem solid;
      position: relative;
      display: block;
      width: 0.5rem;
      left: 2px;
    }

    .container-title {
      display: flex;
      margin: 1rem 1rem 1rem 1rem;
      flex-direction: column;
      .tittle-main {
        font-size: 16px;
        font-weight: 900;
        text-align: left;
        letter-spacing: 0px;
        color: #2b0d61;
      }

      .container-link {
        span {
          font-size: 12px;
          color: #2b0d61;
        }
      }
    }
    .messageInfo > div > div {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: none;
      transition: all 1s ease;
      li {
      }
    }
    .messageInfo > div > div:hover {
      animation: pulseInfo 2s ease infinite;
    }

    .container-title-section {
      .title-right {
        cursor: pointer;
        position: absolute;
        right: 5%;
      }
    }

    .container {
      display: flex;
      .item-text .item-link a span {
        padding-left: 10px;
        text-align: left;
        letter-spacing: 0;
        color: #2b0d61;
        font-size: 15px;
        font-weight: 600;
      }
    }

    hr {
      border: 1px solid #e4e4e4;
      margin: 1.5em 1em 1em;
    }

    .tittle-main {
      margin: 0.5rem 0.2rem;
      text-align: left;
      letter-spacing: 0;
      color: #2b0d61;
      opacity: 1;
      font-size: 16px;
      font-weight: 900;
      font-family: 'BlissPro-ExtraBold', sans-serif;
    }

    .a {
      fill: #bcbbbb;
    }
  }
  .message-pending-claim {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #2b0d61;
  }

  @keyframes pulseInfo {
    0% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(42, 13, 97, 0.514);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 12px rgba(255, 255, 255, 0);
    }
    100% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @media (max-width: 1366px) {
    .body-different-page {
      .container-form-group {
        .label-from-group {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .header {
      height: 200px;
      .title-header {
        font-size: 30px;
      }
    }
    .body-different-page {
      padding: 2rem 2rem 2rem 1.75rem;

      .container__buttons-form {
        display: block;
        margin: 1rem;
        button {
          width: 100%;
          margin: 10px;
        }
      }
    }
  }
`;

export const StyledContainerTable = styled.div`
  width: 85%;
  margin: auto;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const getInfoPreview = (form = []) => {
  return form
    .filter((item) => !item.onlySelect)
    .filter((item) => item.type !== 'action')
    .map((item) => {
      if (item.type === 'select' && item.options) {
        const valueFind = item.options.find((opt) => opt.value.toString() === item.value);

        return {
          header: item.label,
          description: valueFind ? (valueFind.value !== '' ? valueFind.label : '') : '',
          error: item.error,
        };
      }
      if (item.type === 'drop') {
        let value = '';
        if (item.value) {
          item.value.forEach((t, index) => {
            value = value + t.file.name + (index !== item.value.length - 1 ? ', ' : '');
          });
        }

        return { header: 'Archivos adjuntos:', description: value, error: item.error };
      }
      return { header: item.label, description: item.value, error: item.error };
    })
    .filter((item) => {
      return item.type !== 'action';
    });
};

const OptionToRender = ({
  code,
  indexCurrent,
  data,
  form,
  onChangeModePage,
  descriptionHeader,
  fileBase64,
  setEnableButtonBack,
  closeOption,
  onSaveForm,
  loadingSaveForm,
  setIsDifferentPage,
  setShowConstanciaPage,
  useDigitalPolicy,
  beneficiaryInfo,
  onSelectBeneficiary,
  onSelectBeneficiaryBurial,
  onChangeBeneficiary,
  onBlurModePage,
  madeChanges,
  onDeleteBeneficiary,
  beneficiaryInfoBurial,
  onDeleteBeneficiaryBurial,
  policyCurrent,
  policyDataCurrent,
  menuId,
  paramsComprobantes,
  loadingOption,
}) => {
  const [isPreview, setIsPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const availableSendForm = (code) => {
    let isAvailable = true;
    form[code].forEach((item) => {
      if (item.required) {
        if (!item.value) {
          isAvailable = false;
        }
      }
    });
    return isAvailable;
  };

  switch (code) {
    case CodeOptProduct.DCD:
      return loadingOption ? (
        <div className="loading mt-1">
          <PSLoading />
        </div>
      ) : fileBase64[CodeOptProduct.DCD] ? (
        <PSPreviewFile
          poliza={data[indexCurrent]}
          descriptionHeader={descriptionHeader}
          typeDocument="certificado digital"
          fileBase64={fileBase64[CodeOptProduct.DCD]}
        />
      ) : (
        false
      );
    case CodeOptProduct.BLT:
      return false;
    case CodeOptProduct.ADB: // actualización de beneficiario
      return (
        <PSFormChangeBeneficiary
          onCloseOption={() => {
            setIsDifferentPage(false);
            closeOption();
          }}
          isPreview={isPreview}
          form={form}
          beneficiaryInfo={beneficiaryInfo}
          onSelectBeneficiary={onSelectBeneficiary}
          onChangeBeneficiary={onChangeBeneficiary}
          onDeleteBeneficiary={onDeleteBeneficiary}
          onChangeModePage={onChangeModePage}
          onSaveForm={onSaveForm}
          onBlurModePage={onBlurModePage}
          getInfoPreview={getInfoPreview}
          setIsPreview={setIsPreview}
          availableSendForm={availableSendForm}
          menuId={menuId}
          nroPolicy={policyCurrent}
          dataPolicy={policyDataCurrent}
          loadingSaveForm={loadingSaveForm}
        />
      );
    case CodeOptProduct.INCB:
      return (
        <>
          {!isPreview ? (
            <>
              <PSDynamicTable
                headers={HeaderTableBeneficiary}
                showCheck
                valueSelected={beneficiaryInfo.currentBeneficiary}
                items={beneficiaryInfo.listOfBeneficiary}
                onSelect={beneficiaryInfo.onSelectBeneficiary}
              />
              <PSComponentForm
                from={form[CodeOptProduct.INCB]}
                onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.INCB)}
                title="Deseo incluir a nuevos beneficiarios:"
              />
            </>
          ) : (
            <PSList items={getInfoPreview(form[CodeOptProduct.INCB])} />
          )}

          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.INCB)}
              disabled={!availableSendForm(CodeOptProduct.INCB)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>
      );
    case CodeOptProduct.EXCB:
      return (
        <>
          {!isPreview ? (
            <PSComponentForm
              from={form[CodeOptProduct.EXCB]}
              onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.EXCB)}
              title="Deseo excluir a mis beneficiarios registrados"
            />
          ) : (
            <PSList items={getInfoPreview(form[CodeOptProduct.EXCB])} />
          )}

          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.EXCB)}
              loading={loadingSaveForm}
              disabled={!availableSendForm(CodeOptProduct.EXCB)}
            >
              Enviar
            </PSButton>
          </div>
        </>
      );

    case CodeOptProduct.CDG:
      return (
        <PSPreviewFile
          poliza={data[indexCurrent]}
          descriptionHeader={descriptionHeader}
          typeDocument="cuadro de valores garantizados"
        />
      );
    case CodeOptProduct.CGS:
      return (
        <PSFormChangeBeneficiaryBurial
          onCloseOption={() => {
            setIsDifferentPage(false);
            closeOption();
          }}
          isPreview={isPreview}
          form={form}
          beneficiaryInfoBurial={beneficiaryInfoBurial}
          onSelectBeneficiaryBurial={onSelectBeneficiaryBurial}
          onChangeBeneficiary={onChangeBeneficiary}
          onDeleteBeneficiaryBurial={onDeleteBeneficiaryBurial}
          onChangeModePage={onChangeModePage}
          onSaveForm={onSaveForm}
          onBlurModePage={onBlurModePage}
          getInfoPreview={getInfoPreview}
          setIsPreview={setIsPreview}
          availableSendForm={availableSendForm}
          menuId={menuId}
          nroPolicy={policyCurrent}
          loadingSaveForm={loadingSaveForm}
        />
      );
    case CodeOptProduct.IBGS:
      return (
        <>
          {!isPreview ? (
            <PSComponentForm
              from={form[CodeOptProduct.IBGS]}
              onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.IBGS)}
              title="Deseo cambiar al beneficiario original de la cobertura de Gasto de Sepelio por el indicado a continuación:"
            />
          ) : (
            <PSList items={getInfoPreview(form[CodeOptProduct.IBGS])} />
          )}
          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.IBGS)}
              disabled={!availableSendForm(CodeOptProduct.IBGS)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>
      );

    case CodeOptProduct.EBGS:
      return (
        <>
          {!isPreview ? (
            <PSComponentForm
              from={form[CodeOptProduct.EBGS]}
              onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.EBGS)}
              title="Deseo cambiar al beneficiario original de la cobertura de Gasto de Sepelio por el indicado a continuación:"
            />
          ) : (
            <PSList items={getInfoPreview(form[CodeOptProduct.EBGS])} />
          )}
          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.EBGS)}
              disabled={!availableSendForm(CodeOptProduct.EBGS)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>
      );
    case CodeOptProduct.CMP: // cambio modalidad de pago
      return (
        <PSFormChangePayment
          onCloseOption={() => {
            setIsDifferentPage(false);
            closeOption();
          }}
          onChangeModePage={onChangeModePage}
          getInfoPreview={getInfoPreview}
          setIsPreview={setIsPreview}
          onSaveForm={onSaveForm}
          availableSendForm={availableSendForm}
          policyCurrent={policyCurrent}
          menuId={menuId}
          form={form}
          isPreview={isPreview}
          loadingSaveForm={loadingSaveForm}
        />
      );
    case CodeOptProduct.CP:
      return false;
    case CodeOptProduct.CPC:
      return (
        <>
          {!isPreview ? (
            <PSComponentForm
              from={form[CodeOptProduct.CPC]}
              onChange={(e, index) => onChangeModePage(e, index, CodeOptProduct.CPC)}
              title="Deseo cambiar el % del capital asegurado de mis beneficiarios actuales"
            />
          ) : (
            <PSList items={getInfoPreview(form[CodeOptProduct.CPC])} />
          )}
          <div className="container__buttons-form">
            <PSButton onClick={() => setIsPreview(!isPreview)}>
              {!isPreview ? 'Vista previa' : 'Editar'}
            </PSButton>
            <PSButton
              onClick={() => onSaveForm(CodeOptProduct.CPC)}
              disabled={!availableSendForm(CodeOptProduct.CPC)}
              loading={loadingSaveForm}
            >
              Enviar
            </PSButton>
          </div>
        </>
      );

    case CodeOptProduct.DCP:
      return (
        paramsComprobantes !== null && (
          <PSTableComprobantes
            poliza={data[indexCurrent]}
            onCloseOption={closeOption}
            descriptionHeader={descriptionHeader}
            typeDocument="comprobante de pago"
            menuId={menuId}
            paramsComprobantes={paramsComprobantes}
          />
        )
      );

    case CodeOptProduct.CDA:
      return loadingOption ? (
        <div className="loading mt-1">
          <PSLoading />
        </div>
      ) : fileBase64[CodeOptProduct.CDA] ? (
        <PSPreviewFile
          poliza={data[indexCurrent]}
          descriptionHeader={descriptionHeader}
          typeDocument={DescriptionForm.CDA}
          fileBase64={fileBase64[CodeOptProduct.CDA]}
        />
      ) : (
        false
      );

    case CodeOptProduct.CDLA:
      return menuId && menuId === '20000004' ? (
        loadingOption ? (
          <div className="loading mt-1">
            <PSLoading />
          </div>
        ) : fileBase64[CodeOptProduct.DCG] ? (
          <PSPreviewFile
            poliza={data[indexCurrent]}
            descriptionHeader={descriptionHeader}
            typeDocument="constancia de Listado de Asegurados"
            fileBase64={fileBase64[CodeOptProduct.DCG]}
            editPage
          />
        ) : (
          false
        )
      ) : (
        <PSPreviewFileHtml
          typeClassPrint="asegurado"
          descriptionHeader={descriptionHeader}
          typeDocument="constancia de Listado de Asegurados"
        >
          <PSConstanciaAseguradoSeguro
            poliza={data[indexCurrent]}
            id="canvas-wrapper-content"
            onCloseOption={closeOption}
          />
        </PSPreviewFileHtml>
      );

    case CodeOptProduct.CICA:
      return (
        <PSTableIndividualAsegurado
          poliza={data[indexCurrent]}
          onCloseOption={closeOption}
          descriptionHeader={descriptionHeader}
          typeDocument="constancia de Listado de Asegurados"
          menuId={menuId}
        />
      );
    case CodeOptProduct.CPS:
      return loadingOption ? (
        <div className="loading">
          <PSLoading />
        </div>
      ) : fileBase64[CodeOptProduct.CPS] ? (
        <PSPreviewFile
          poliza={data[indexCurrent]}
          descriptionHeader={descriptionHeader}
          typeDocument="constancia de pensionista"
          fileBase64={fileBase64[CodeOptProduct.CPS]}
        />
      ) : (
        false
      );

    case CodeOptProduct.DP:
      return (
        <>
          {loading && (
            <div className="loading mt-1">
              <PSLoading />
            </div>
          )}

          <PSPreviewFile
            poliza={data[indexCurrent]}
            descriptionHeader={descriptionHeader}
            typeDocument="póliza"
            onCloseOption={closeOption}
            useDigitalPolicy
            onSetLoading={(value) => setLoading(value)}
            menuId={menuId}
          />
        </>
      );
    case CodeOptProduct.DCDVI:
      return (
        <PSCertificadoDigital
          poliza={data[indexCurrent]}
          descriptionHeader={descriptionHeader}
          typeDocument="Póliza Digital"
          onCloseOption={closeOption}
          menuId={menuId}
        />
      );
    case CodeOptProduct.LDP:
      return false;
    case CodeOptProduct.GLC:
      return <PSGeolocation activateBackButton={() => setEnableButtonBack(true)} />;
    case CodeOptProduct.MDT:
      return (
        <PSUpdateCard
          poliza={data[indexCurrent]}
          typeDocument="Modificación de tarjeta para cobro recurrente"
          onCloseOption={closeOption}
          menuDescription={descriptionHeader}
        />
      );
    case CodeOptProduct.CUP:
      return (
        <PSExpiredCoupons
          poliza={data[indexCurrent]}
          optionDescription="Cupones Pendientes"
          descriptionHeader={descriptionHeader}
          onCloseOption={closeOption}
        />
      );
    case CodeOptProduct.HCT:
      return (
        <PSHistoryCard
          poliza={data[indexCurrent]}
          typeDocument="Historial de cambio de tarjeta para cobro recurrente"
          onCloseOption={closeOption}
        />
      );

    default:
      return <div>code {code}</div>;
  }
};

const PSBodyProduct = React.forwardRef(
  (
    {
      img,
      imgMobile,
      descriptionHeader,
      iconHeader,
      loading,
      data,
      headerTable,
      questions,
      coverages,
      linkToSchedule,
      linkToTicket,
      form,
      onChangeModePage,
      fileBase64,
      options,
      onSelectItem,
      showCheck,
      urlToProduct,
      withButtonToProduct,
      messageToProduct,
      textButtonToProduct,
      withLinkToProduct,
      showMostConsulted,
      dataQl,
      branchId,
      productId,
      updateForm,
      clearForms,
      useDigitalPolicy,
      beneficiaryInfo,
      beneficiaryInfoBurial,
      onSelectBeneficiary,
      onSelectBeneficiaryBurial,
      onChangeBeneficiary,
      onDeleteBeneficiary,
      onDeleteBeneficiaryBurial,
      goBackFunction,
      complementInfo,
      policiesMessage,
      menuId,
      menuDescription,
      onBlurModePage,
      onSetCurrentCodeForm,
      madeChanges,
      isClaim,
      paramsComprobantes,
      loadingOption,
      ...props
    },
    ref
  ) => {
    const history = useHistory();
    const ga = React.useContext(GAProvider);

    const [currentOption, setCurrentOption] = useState({});
    const [showDetailProduct, setShowDetailProduct] = useState(true);
    const [indexCurrent, setIndexCurrent] = useState(-1);
    const [isDifferentPage, setIsDifferentPage] = useState(false);
    const [currentIndexOption, setCurrenIndexOption] = useState(-1);
    const [enableButtonBack, setEnableButtonBack] = useState(false);
    const [showModalForm, setShowModalForm] = useState(false);
    const [loadingSaveForm, setLoadingSaveForm] = useState(false);
    const [showModalValidation, setShowModalValidation] = useState(false);
    const [currentCodeForm, setCurrentCodeForm] = useState(null);
    const [showConstanciaPage, setShowConstanciaPage] = useState(false);
    const { userInfo, attributesCore } = useSelector((state) => state.auth);
    const isBusiness = userInfo ? userInfo.isBusiness : false;

    const [currentInfoForm, setCurrentInfoForm] = useState(null);
    const [policyCurrent, setPolicyCurrent] = useState(null);
    const [policyDataCurrent, setPolicyDataCurrent] = useState({ rol: 'Beneficiario' });
    const [currentParamsForm, setCurrentParamsForm] = useState(null);
    const [hasError, setHasError] = useState(false);
    const { address } = useSelector((state) => state.auth);
    const { policyUpdated } = props;
    const [showModalUpdateData, setShowModalUpdateData] = useState(false);
    const [currentTypeForm, setCurrentTypeForm] = useState('');

    const attributes = userInfo && userInfo.attributes ? userInfo.attributes : userDefault;
    const fullName = `${attributes?.middleName} ${attributes?.familyName} ${attributes?.givenName}`;
    const onClickOption = (index, code, onClick) => {
      const dataPolicy = indexCurrent !== -1 ? data[indexCurrent] : { rol: 'Beneficiario' };
      const nrPolicy = indexCurrent !== -1 ? data[indexCurrent].nroPolicy : 0;
      setCurrentCodeForm(code);

      if (onSetCurrentCodeForm) {
        onSetCurrentCodeForm(code);
      }

      switch (code) {
        case CodeOptProduct.CP:
          history.push(linkToSchedule);
          break;
        case CodeOptProduct.BLT:
          history.push(`${linkToTicket}/${nrPolicy}`);
          break;
        case CodeOptProduct.LDP:
          history.push(`${linkToTicket}/${nrPolicy}`);
          break;

        default: {
          if (index === currentIndexOption) {
            setCurrenIndexOption(-1);
            setCurrentTypeForm('');
          } else {
            setCurrenIndexOption(index);
            setPolicyCurrent(nrPolicy);
            setPolicyDataCurrent(dataPolicy);
            if (
              [
                CodeOptProduct.ADB,
                CodeOptProduct.CMP,
                CodeOptProduct.CPC,
                CodeOptProduct.GLC,
                CodeOptProduct.CGS,
                CodeOptProduct.CGS,
                CodeOptProduct.EBGS,
                CodeOptProduct.IBGS,
                CodeOptProduct.EXCB,
                CodeOptProduct.INCB,
              ].includes(code)
            ) {
              setIsDifferentPage(true);
              sendAnalytics(DescriptionForm[code] || '');
              setCurrentTypeForm(DescriptionForm[code] || '');
            }
          }
        }
      }
    };

    const sendAnalytics = (typeForm) => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${descriptionHeader}-${typeForm}`,
        action: 'click',
        label: `El usuario visitó la pagina  de ${typeForm} de ${descriptionHeader}`,
        value: 1,
      });
    };
    const onOTPValidation = (typeOTP = 'send', typeSend) => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      const labelTypeSendGA = typeSend === 'phoneNumber' ? 'celular' : 'email';
      let labelGA = '';
      switch (typeOTP) {
        case 'send':
          labelGA = `El usuario solicitó el envío de la clave OTP por ${labelTypeSendGA}`;
          break;
        case 'success':
          labelGA = `El usuario envió el formulario de ${currentTypeForm} exitosamente`;
          break;
        case 'error':
          labelGA = `El usuario introdujo una clave OTP errónea enviada por ${labelTypeSendGA}`;
          break;
      }

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${descriptionHeader}-${currentTypeForm}`,
        action: 'click',
        label: labelGA,
        value: 1,
      });
    };

    const validateFieldForm = (code) => {
      let isValid = true;
      if (code) {
        form[code].forEach((item) => {
          if (item.required) {
            if (!item.value) {
              item.error = 'Campo requerido';
              isValid = false;
            } else {
              item.error = '';
            }
            if (item.maxLength && item.value) {
              if (item.value.length !== item.maxLength) {
                item.error = `Ingrese ${item.maxLength} digitos`;
                isValid = false;
              } else {
                item.error = '';
              }
            }
            if (item.maxValue && item.value) {
              if (item.value > item.maxValue) {
                item.error = `Ingrese un valor menor a ${item.maxValue}`;
                isValid = false;
              } else {
                item.error = '';
              }
            }
            if (item.minValue && item.value) {
              if (item.value < item.minValue) {
                item.error = `Ingrese un valor mayor a ${item.minValue - 1}`;
                isValid = false;
              } else {
                item.error = '';
              }
            }
          }
        });
      } else {
        isValid = false;
      }
      if (code === 'ADB') {
        let valueOfPercentage = 0;
        beneficiaryInfo.listOfBeneficiary.forEach(
          (item) => (valueOfPercentage += parseFloat(item.percentage))
        );
        if (valueOfPercentage !== 100) {
          isValid = false;
          const messsage =
            beneficiaryInfo.listOfBeneficiary.length === 0
              ? 'Debe declarar por lo menos un Beneficiario'
              : 'La suma del porcentaje de beneficiarios debe ser 100';
          alert(messsage);
        }
      }

      updateForm(form);
      if (isValid) {
        setCurrentCodeForm(code);
        onSaveForm();
      }
    };
    const onSaveForm = async () => {
      const code = currentCodeForm;
      try {
        setLoadingSaveForm(true);
        const files = findValueByField(form[code], 'documents');
        const headers = { policy: form[code][0].value, 'form-id': getCodeForm(code) };
        setCurrentParamsForm({ ...headers });

        const dataFilePost = files.map((file) => {
          const nameSplit = file.file.name.split('.');
          const typeSplit = file.file.type.split('/');
          return {
            file: nameSplit[0],
            type: typeSplit[1],
          };
        });
        const responseFile = await FormsService.uploadFileForms(dataFilePost, headers);
        let statusUpload = false;
        let attachments = [];

        if (responseFile.status.id === 200) {
          attachments = responseFile.data.map((item) => {
            return { id: item.id };
          });

          try {
            for (let i = 0; i < files.length; i++) {
              const responseUpload = await FormsService.uploadFile(
                responseFile.data[i].presingedUrl,
                files[i].file,
                files[i].file.type
              );
            }
            statusUpload = true;
          } catch (e) {
            statusUpload = false;
          }
        }
        setLoadingSaveForm(true);

        if (statusUpload) {
          // todo validate with response
          let description;
          if (code) {
            description = form[code].map((item) => {
              return { key: item.field, value: item.value };
            });

            if (code === 'ADB') {
              description.push({ key: 'beneficiaries', value: beneficiaryInfo.listOfBeneficiary });
            }
            if (code === 'CGS') {
              description.push({
                key: 'beneficiaries',
                value: beneficiaryInfoBurial.listOfBeneficiary,
              });
            }
            const data = {
              fullName: capitalizeSentence(fullName),
              branchId, // branchId
              productId, // productId
              segment: menuId,
              segmentDescription: menuDescription,
              email: attributesCore.email ? attributesCore.email : '',
              address,
              policy: form[code][0].value,
              ubigeo: null,
              monto: null, // todo jordy ?
              description,
              attachments,
            };

            setLoadingSaveForm(true);
            const response = await FormsService.saveForms(data, headers);

            if (response.needValidation) {
              const { url } = response;
              setCurrentInfoForm({ url, data });
              setTimeout(() => {
                setShowModalValidation(true);
                const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
                ga.pageview(window.location.pathname + window.location.search);
                ga.event({
                  category: `${messageTypeUser}Producto ${descriptionHeader}-${currentTypeForm}`,
                  action: 'click',
                  label: `El usuario inició el flujo de envío del formulario de ${currentTypeForm}`,
                  value: 1,
                });
              }, 1500);
            }
          }
        } else {
          console.log('error');
          setLoadingSaveForm(false);
          setHasError(true);
        }
      } catch (error) {
        console.log('error', error);
        setLoadingSaveForm(false);
        setHasError(true);
      }
    };

    const closeModalForm = () => {
      setShowModalForm(false);
      clearForms(currentCodeForm);
      if (enableButtonBack) {
        const btnBack = document.getElementById('btnBack');
        btnBack.click();
        setEnableButtonBack(false);
      } else {
        setIsDifferentPage(false);
        setCurrenIndexOption(-1);
      }
    };

    const closeModalValidation = async (saveForm) => {
      setShowModalValidation(false);
      if (saveForm) {
        setShowModalForm(true);
        setLoadingSaveForm(false);
      } else {
        setLoadingSaveForm(false);
        setHasError(true);
      }
    };

    const acceptModalError = () => {
      setHasError(false);
      setIsDifferentPage(false);
      setCurrenIndexOption(-1);
      if (clearForms) {
        clearForms(currentCodeForm);
      }
    };

    const enableIndex = (index, policies = []) => {
      let indexSelected = index;
      if (policyUpdated && policies.length > 0) {
        indexSelected = policies.findIndex((p) => p.nroPolicy == policyUpdated);
        indexSelected = indexSelected === -1 ? 0 : indexSelected;
      }
      onSelectItem(indexSelected);
      setIndexCurrent(indexSelected);
    };

    const onCloseModalUpdateData = () => {
      setShowModalUpdateData(false);
      setIsDifferentPage(false);
      setCurrenIndexOption(-1);
      if (clearForms) {
        clearForms(currentCodeForm);
      }
    };

    useMemo(() => {
      if (data.length >= 1) {
        const { producto, status, rol } = data[0];
        enableIndex(0, data);
        /*
         TODO : validations to select default policy
        if (producto === 'Vida con Devolución Protecta') {
          if (rol === 'Contratante') enableIndex(0, data);
          else {
            const firsPolicyEnabled = data.findIndex((d) => d.rol === 'Contratante');
            if (firsPolicyEnabled !== -1) enableIndex(firsPolicyEnabled, data);
          }
        } else if (status === 'Vigente') enableIndex(0, data);
        else {
          const firsPolicyEnabled = data.findIndex((d) => d.status === 'Vigente');
          if (firsPolicyEnabled !== -1) enableIndex(firsPolicyEnabled, data);
        } */
      }
    }, [data, policyUpdated]);

    useEffect(() => {
      if (policyUpdated && menuId === menuCode.VIDA_DEVOLUCION) {
        setCurrentCodeForm(CodeOptProduct.MDT);
        const indexMDT = options?.findIndex((e) => e.code === CodeOptProduct.MDT);
        const indexPolicy = data?.findIndex((e) => e.nroPolicy === policyUpdated);
        if (indexMDT !== -1) {
          const dataPolicy = data[indexPolicy];
          setCurrenIndexOption(indexMDT);
          setPolicyCurrent(policyUpdated);
          setPolicyDataCurrent(dataPolicy);
        }
      }
    }, [policyUpdated]);

    return isDifferentPage ? (
      <StyledPsBodyProduct ref={ref} isDifferentPage>
        <div className="header">
          <PSHeaderIntranet
            img={img}
            imgMobile={imgMobile}
            description={options[currentIndexOption].title}
            icon={iconHeader}
            goBackFunction={() => {
              if (enableButtonBack) {
                const btnBack = document.getElementById('btnBack');
                btnBack.click();
                setEnableButtonBack(false);
              } else {
                // comprobar si es ADB y si tiene cambios
                if (madeChanges && currentCodeForm.trim() === 'ADB') {
                  setShowModalUpdateData(true);
                } else {
                  setIsDifferentPage(false);
                  setCurrenIndexOption(-1);
                  if (clearForms) {
                    clearForms(currentCodeForm);
                  }
                }
              }
            }}
            {...props}
          />
        </div>
        {showConstanciaPage ? (
          <PSTableIndividualAsegurado
            poliza={data[indexCurrent]}
            onCloseOption={() => setCurrenIndexOption(-1)}
            descriptionHeader={descriptionHeader}
            typeDocument="constancia individual de cada Asegurado"
          />
        ) : loading ? (
          <div className="loading-pages-intranet">
            <PSLoading />
          </div>
        ) : (
          <div className="body-different-page">
            <OptionToRender
              menuId={menuId}
              policyCurrent={policyCurrent}
              policyDataCurrent={policyDataCurrent}
              code={options[currentIndexOption].code}
              indexCurrent={currentIndexOption}
              data={data}
              headers={headerTable}
              form={form}
              onChangeModePage={onChangeModePage}
              onBlurModePage={onBlurModePage}
              madeChanges={madeChanges}
              setEnableButtonBack={setEnableButtonBack}
              closeOption={() => setCurrenIndexOption(-1)}
              onSaveForm={(code) => {
                validateFieldForm(code);
              }}
              // onSaveForm={(code)=> {onSaveForm(code)}}
              setIsDifferentPage={setIsDifferentPage}
              setShowConstanciaPage={setShowConstanciaPage}
              loadingSaveForm={loadingSaveForm}
              useDigitalPolicy
              beneficiaryInfo={beneficiaryInfo}
              beneficiaryInfoBurial={beneficiaryInfoBurial}
              paramsComprobantes={paramsComprobantes}
              onSelectBeneficiary={onSelectBeneficiary}
              onSelectBeneficiaryBurial={onSelectBeneficiaryBurial}
              onChangeBeneficiary={onChangeBeneficiary}
              onDeleteBeneficiary={onDeleteBeneficiary}
              onDeleteBeneficiaryBurial={onDeleteBeneficiaryBurial}
              loadingOption={loadingOption}
            />
          </div>
        )}

        {showModalForm && (
          <PSModalSuccess
            openModal={showModalForm}
            title="Solicitud exitosa"
            message="Gracias por escribirnos. En un plazo no mayor a 48 horas útiles nuestros
          ejecutivos estarán revisando tu requerimiento para buscar responderte a la
          brevedad. Asimismo, agradeceremos considerar que nuestro horario de
          atención es de lunes a viernes de 9 a.m. a 6 p.m. excepto feriados"
            buttonText="Aceptar"
            onClick={closeModalForm}
          />
        )}

        {showModalValidation && (
          <Modal
            animationDuration={0}
            open={showModalValidation}
            onClose={() => {}}
            showCloseIcon={false}
            onOverlayClick={() => {}}
            closeOnOverlayClick={false}
          >
            <OTPValidation
              url={currentInfoForm.url}
              dataSave={currentInfoForm.data}
              onValid={(isValid) => {
                closeModalValidation(isValid);
                isValid && onOTPValidation('success');
              }}
              onSendValidation={(typeSend) => {
                onOTPValidation('send', typeSend);
              }}
              onSendErrorCode={(typeSend) => {
                onOTPValidation('error', typeSend);
              }}
              onCloseModal={() => {
                setShowModalValidation(false);
                setLoadingSaveForm(false);
              }}
              headerParams={currentParamsForm}
            />
          </Modal>
        )}

        {showModalUpdateData && (
          <Modal
            animationDuration={0}
            open={showModalUpdateData}
            onClose={() => {}}
            showCloseIcon={false}
            onOverlayClick={() => {}}
          >
            <PSMessageResponse icon="failed" onClose={() => setShowModalUpdateData(false)}>
              <p>La información ingresada no se guardará. ¿Estás seguro de abandonar la página?</p>
              <div className="m-1">
                <PSButton onClick={onCloseModalUpdateData}>Aceptar</PSButton>
              </div>
            </PSMessageResponse>
          </Modal>
        )}

        {hasError && <PSModalErrorResponse onClick={acceptModalError} />}
      </StyledPsBodyProduct>
    ) : (
      <StyledPsBodyProduct ref={ref}>
        <div className="header">
          <PSHeaderIntranet
            img={img}
            imgMobile={imgMobile}
            description={descriptionHeader}
            icon={iconHeader}
            goBackFunction={goBackFunction}
            {...props}
          />
        </div>
        {loading ? (
          <div className="loading-pages-intranet">
            <PSLoading />
          </div>
        ) : (
          <div className={`body ${data.length === 0 ? 'body-optionRegister' : ' '}`}>
            {data.length === 0 ? (
              <PSOptionRegisterPolicy
                url={urlToProduct}
                message={messageToProduct}
                textButton={textButtonToProduct}
                withLink={withLinkToProduct}
                withButton={withButtonToProduct}
                isClaim={isClaim}
              />
            ) : (
              <>
                <div className="d-flex">
                  {showDetailProduct ? (
                    <PSIcon
                      type="arrowUp"
                      className="m-medium"
                      onClick={() => setShowDetailProduct(!showDetailProduct)}
                    />
                  ) : (
                    <PSIcon
                      type="arrowDown"
                      className="m-medium"
                      onClick={() => setShowDetailProduct(!showDetailProduct)}
                    />
                  )}
                  <div className="tittle-main d-flex">
                    <label>{`Detalle del seguro ${complementInfo}`}</label>
                    {policiesMessage && (
                      <PSIcon
                        type="info"
                        className="ml-2 messageInfo"
                        data-html="true"
                        data-tip={policiesMessage}
                      />
                    )}
                  </div>
                </div>
                {data.length > 0 && showDetailProduct && (
                  <PSDynamicTable
                    dataQl={dataQl}
                    headers={headerTable}
                    items={data}
                    menuId={menuId}
                    showCheck={showCheck}
                    valueSelected={indexCurrent}
                    onSelect={(index) => {
                      onSelectItem(index);
                      setIndexCurrent(index);
                    }}
                    isLastColumnCentered
                  />
                )}
                {isClaim && (
                  <div className="message-pending-claim my-0 mt-1">
                    <p>
                      *En caso hayas generado tu reclamo por la Net Privada, podrás visualizar tu
                      registro en esta sección en un plazo no mayor de 48 horas hábiles.
                    </p>
                    <p>
                      Asimismo, agradecemos considerar que nuestro horario de atención es de Lunes a
                      Viernes de 09:00 am a 06:00 pm, excepto feriados.
                    </p>
                  </div>
                )}

                {options.length > 0 && <hr />}

                {options.map((option, index) => (
                  <PSOptionsBodyProduct
                    policyCurrent={data[indexCurrent]}
                    id={option.code}
                    hasToolTip={indexCurrent === -1}
                    hasError={['DCG'].includes(option.code) && !fileBase64[option.code]} // , 'DCP'
                    key={option.code}
                    typeIcon={option.icon}
                    title={option.title}
                    activeOption={currentIndexOption === index}
                    onClick={() => onClickOption(index, option.code, option.onClick)}
                  >
                    {currentIndexOption === index && (
                      <OptionToRender
                        menuId={menuId}
                        policyCurrent={policyCurrent}
                        policyDataCurrent={policyDataCurrent}
                        code={option.code}
                        indexCurrent={indexCurrent}
                        data={data}
                        form={form}
                        headers={headerTable}
                        madeChanges={madeChanges}
                        onChangeModePage={onChangeModePage}
                        descriptionHeader={descriptionHeader}
                        fileBase64={fileBase64}
                        setEnableButtonBack={setEnableButtonBack}
                        closeOption={() => setCurrenIndexOption(-1)}
                        setIsDifferentPage={setIsDifferentPage}
                        setShowConstanciaPage={setShowConstanciaPage}
                        useDigitalPolicy
                        paramsComprobantes={paramsComprobantes}
                        loadingOption={loadingOption}
                      />
                    )}
                  </PSOptionsBodyProduct>
                ))}
                <ReactTooltip place="right" type="dark" />
                <hr />
                {isEmpty(currentOption) ? (
                  <>
                    {showMostConsulted && (
                      <div className="section-titulo mt-1">
                        <PSLabel className="titulo">Más consultados {descriptionHeader}</PSLabel>
                      </div>
                    )}
                    {showMostConsulted && (
                      <div className="columns mt-1 display-none-mobile">
                        {services.map((service, index) => (
                          <PSGrayBoxContainer
                            key={service.description}
                            description={service.description}
                            icon={service.icon}
                            className="column is-one-third box-container-home"
                            onClick={() => setCurrentOption({ ...services[index] })}
                          />
                        ))}
                      </div>
                    )}
                    {showMostConsulted && (
                      <div className="columns mt-1 display-only-mobile">
                        <PSCarousel typeIcon="secondary">
                          {services.map((service, index) => (
                            <PSGrayBoxContainer
                              key={service.description}
                              description={service.description}
                              icon={service.icon}
                              className="column is-one-third box-container-home"
                              onClick={() => setCurrentOption({ ...services[index] })}
                            />
                          ))}
                        </PSCarousel>
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <div className="container-title-section">
                      <PSLabel className="titleQuestion">{currentOption.description}</PSLabel>
                      <div
                        onClick={() => {
                          setCurrentOption({});
                        }}
                      >
                        <PSLabel className="title-right">{'<< Atrás'} </PSLabel>
                      </div>
                    </div>

                    {currentOption.description === 'Preguntas frecuentes' && (
                      <PSQuestion questions={questions} />
                    )}

                    {currentOption.description === 'Resumen de coberturas' && (
                      <PSCoverage ListCoverage={coverages} />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </StyledPsBodyProduct>
    );
  }
);

PSBodyProduct.displayName = 'PSBodyProduct';

PSBodyProduct.defaultProps = {
  className: null,
  hasTitle: false,
  img: 'soat-header',
  loading: true,
  data: [],
  questions: {},
  coverages: {},
  form: {},
  options: [],
  onSelectItem: () => {},
  showCheck: true,
  showMostConsulted: true,
  dataQl: [],
  branchId: '',
  productId: '',
  updateForm: () => {},
  clearForms: () => {},
  onChangeBeneficiary: () => {},
  onDeleteBeneficiary: () => {},
  useDigitalPolicy: false,
  goBackFunction: null,
  complementInfo: '',
  policiesMessage: null,
  loadingOption: false,
};

PSBodyProduct.propTypes = {
  className: PropTypes.string,
  showCheck: PropTypes.bool,
  hasTitle: PropTypes.bool,
  img: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  headerTable: PropTypes.array,
  questions: PropTypes.array,
  coverages: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array,
  onSelectItem: PropTypes.func,
  showMostConsulted: PropTypes.bool,
  dataQl: PropTypes.array,
  branchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateForm: PropTypes.func,
  clearForms: PropTypes.func,
  useDigitalPolicy: PropTypes.bool,
  goBackFunction: PropTypes.func,
  policiesMessage: PropTypes.string,
  loadingOption: PropTypes.bool,
};

export default PSBodyProduct;
