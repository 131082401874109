import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import "./index.scss"

const PSModalFAD = ({ icon, isVisible, handlerIcon, title, description }) => {

    const handlerClose = () => {
        handlerIcon()
    }
    return (
        isVisible && (
            <div className='container-modal'>
                <div className="close" onClick={handlerClose}>
                    <PSIcon type="close" className="modal-close-icon" />
                </div>
                <div className='modal-fad'>
                    <div className='modal-icon'>
                        <PSIcon type={icon} />
                    </div>
                    <div className='modal-title'>{title}</div>
                    <div className='modal-description'>
                        {description}
                    </div>
                </div>

            </div>
        )
    )
}

PSModalFAD.defaultProps = {
    icon: 'alertFAD',
    isVisible: false,
    title: 'title',
    description:
        (
            <span>
                Has superado el límite de intentos.<br />
                Recuerda que también puedes realizar tus requerimientos llamando al 01-391-3000,
                de lunes a viernes de 9:00 a.m. a 6:00 p.m. excepto feriados.
                Podrás realizar un nuevo intento luego de 24 horas ¡Te esperamos!
            </span>
        )
}

PSModalFAD.propTypes = {
    icon: PropTypes.string,
    isVisible: PropTypes.bool,
    handlerIcon: PropTypes.func,
    title: PropTypes.string
}

export default PSModalFAD