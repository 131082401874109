import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import moment from 'moment-timezone';

import Auth from '../../Amplify';
import { GAConsumer, GAProvider } from './PSAnalytics';
import {
  FIELD_NEXTTIME_LOGIN,
  NEED_UPDATE_PASSWORD,
  STORAGE_TOKEN_COGNITO,
  SUCCESS,
} from '../../util/Constant';
import { Routes } from '../../routes';
import { cleanAuth, createErrorPage, setEdit } from '../redux/auth';
import { logout, setAttributesCore, setLoginCore, setLoginUser } from '../redux/auth/actions/user-login';
import ClientsService from '../shared/services/ClientsService';

import {
  PSModalCheckPromotions,
  PSModalErrorResponse,
  PSModalSatisfactionSurvey,
} from './organisms';
import { PSChatBoot } from './molecules';
import PSMessageResponse from './PSMessageResponse';
import PSButton from './PSButton';
import SurveyService from '../shared/services/SurveyService';
import PSHeaderPageHome from './PSHeaderPageHome';
import PSAsideBar from './PSAsideBar';
import PSNavBar from './PSNavBar';
import PSFooterPageIntranet from './PSFooterPageIntranet';

const SECONDS_RELOAD = 3600000;
const SESSION_TIME = 15;

const BodyContainer = styled.div`
  margin-bottom: 0 !important;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  .columns {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .body-left {
    width: ${(props) => (props.expandBar ? '370px' : '7%')};
    transition: all 0.5s ease;
    margin-bottom: 0;
    @media (max-width: 1300px) {
      width: ${(props) => (props.expandBar ? '25%' : '7%')};
    }
  }
  .body-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  @media (max-width: 1024px) {
    .body-left {
      display: none;
    }

    .body-right {
      width: 100%;
    }
  }
  .modal-sessionExpired {
    background: transparent linear-gradient(90deg, #ed6e00 0%, #2b0d61 100%) 0% 0% no-repeat !important;
  }
`;

const PageContainer = styled.div`
  .navbar-home-only-mobile {
    display: none;
  }
  @media (max-width: 1024px) {
    .header-home {
      display: none;
    }
    .navbar-home-only-mobile {
      display: block;
    }
  }
`;
export default function PSHome({ children }) {
  const dispatch = useDispatch();
  const { attributesCore, userInfo, isEditData, errorPage } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const pdpNetPrivada = userInfo && userInfo.attributes ? userInfo.attributes.pdpNetPrivada : false;
  const firstApperAgain =
    userInfo && userInfo.attributes ? userInfo.attributes.firstApperAgain : false;
  const hasErrorPage = errorPage && errorPage.state;

  const [expandBar, setExpandBar] = useState(true);
  const [showBodyContainer, setShowBodyContainer] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalUpdateData, setShowModalUpdateData] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [valueSelected, setValueSelected] = useState(-1);
  const [showModalSurvey, setShowModalSurvey] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [disabledSignOut, setDisabledSignOut] = useState(false);

  const history = useHistory();
  const ga = React.useContext(GAProvider);
  const location = useLocation();
  const isHome = location.pathname === '/intranet';

  const onClose = () => {
    setExpandBar(!expandBar);
  };

  const openAsideBar = () => {
    setShowBodyContainer(false);
  };

  const closeAsideBar = () => {
    setShowBodyContainer(true);
  };

  const handleWindowSizeChange = () => {
    if (window.innerWidth >= 720) {
      setShowBodyContainer(true);
    }
    if (window.innerWidth <= 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const onClickAcceptPromotions = async (accept) => {
    const dataUpdate = {
      data: {
        speciality: attributesCore.speciality,
        nnationality: attributesCore.nnationality,
        ntitle: attributesCore.ntitle,
        names: attributesCore.names,
        lastName: attributesCore.lastName,
        lastName2: attributesCore.lastName2,
        legalName: attributesCore.legalName,
        sexClient: attributesCore.sexClient,
        indPDP: accept,
        civilState: attributesCore.civilState,
        sblockade: attributesCore.sblockade,
        sblocklaft: attributesCore.sblocklaft,
        indSisClient: attributesCore.indSisClient,
        indPolizaElec: attributesCore.indPolizaElec,
        indProtegData: '1',
        indCuspp: attributesCore.indCuspp,
      },
    };

    try {
      setLoading(true);
      const response = await ClientsService.responseUpdateClientPersonal(dataUpdate);
      if (response.statusCode === 200) {
        dispatch(setLoginCore({ ...attributesCore, indPDP: accept }));
        if (!accept) {
          const user = await Auth.currentAuthenticatedUser();
          const params = {};
          params['custom:first_appear_again'] = '1';
          const result = await Auth.updateUserAttributes(user, params);
          setLoading(false);
          if (result === SUCCESS) {
            const userInfoUpdate = userInfo;
            userInfoUpdate.attributes.firstApperAgain = '1';
            dispatch(setLoginUser({ ...userInfoUpdate }));
            setShowModalCheck(false);
          } else {
            setShowModalCheck(false);
          }
        } else {
          setLoading(false);
          setShowModalCheck(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  const saveSurvey = async (value) => {
    try {
      setDisabledSignOut(true);
      if (value && !isNaN(value)) {
        setValueSelected(value);
      } else {
        value = undefined;
      }
      const data = {
        survey: {
          surveyId: 'SATISFACCION12345',
          originChannel: isMobile ? '25' : '15',
          rank: value,
          surveyStatus: !!value,
          email: attributesCore.email || '-',
        },
      };
      setShowModalSurvey(false);
      const response = await SurveyService.saveSurvey(data);

      const user = await Auth.currentAuthenticatedUser();
      const params = {};
      params['custom:last_survey'] = value ? response.status.id.toString() : '-1';
      const result = await Auth.updateUserAttributes(user, params);

      if (result === SUCCESS) {
        const userInfoUpdate = userInfo;
        userInfoUpdate.attributes.lastSurvey = response.status.id;
        dispatch(setLoginUser({ ...userInfoUpdate }));
      }
    } catch (error) {
      console.log(error);
      setShowModalSurvey(false);
    } finally {
      setDisabledSignOut(false);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    const props = { isMobile };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  const onCloseModalUpdateData = () => {
    setShowModalUpdateData(false);
    if (nextPage) {
      history.push({
        pathname: nextPage?.page,
        state: nextPage?.state,
      });
    }
  };

  const onOpenModalUpdateData = (page, state) => {
    setShowModalUpdateData(true);
    setNextPage({ page, state });
    dispatch(setEdit(false));
  };

  const redirectToLogin = () => {
    dispatch(cleanAuth());
    dispatch(logout())
    localStorage.removeItem(STORAGE_TOKEN_COGNITO);
    localStorage.removeItem(FIELD_NEXTTIME_LOGIN);
    localStorage.clear();
    window.location.replace(Routes.LOGIN);
  };

  const loadModal = () => {
    const actualDate = new Date();
    const loginDate =
      localStorage.getItem(FIELD_NEXTTIME_LOGIN) || moment().startOf('day').fromNow();
    if (loginDate) {
      const duration = moment.duration(moment(actualDate).diff(loginDate)).asMinutes();
      if (duration >= SESSION_TIME) {
        setShowSessionExpired(true);
      }
    }
  };

  const acceptError = () => {
    dispatch(createErrorPage({ state: false }));
    if (errorPage.onAction) {
      errorPage.onAction();
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      loadModal();
    }, SECONDS_RELOAD);

    if (!showSessionExpired) {
      loadModal();
    }
  });

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
  }, []);

  useEffect(() => {
    const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO);
    const needUpdatePassword = localStorage.getItem(NEED_UPDATE_PASSWORD);

    if (idTokenCognito === null) {
      history.push(Routes.LOGIN);
    } else if (!attributesCore) {
      dispatch(setAttributesCore());
    }

    if (needUpdatePassword === '1') {
      history.push(Routes.LOGIN);
    }
  }, []);

  useEffect(() => {
    if (!firstApperAgain) {
      if (attributesCore) {
        const { indPDP } = attributesCore;
        if (pdpNetPrivada === false && indPDP === false) {
          setShowModalCheck(true);
        }
        if (pdpNetPrivada === true && indPDP === false) {
          onClickAcceptPromotions(true);
        }
      }
    } else {
      setShowModalCheck(false);
    }
  }, [attributesCore]);

  useEffect(() => {
    setTimeout(() => {
      setShowModalSurvey(true);
    }, 30000);
  }, []);

  return (
    <PageContainer>
      <PSHeaderPageHome
        hasTitle
        className="header-home"
        onOpenModalUpdateData={onOpenModalUpdateData}
        disabledSignOut={disabledSignOut}
      />
      <div className="navbar-home-only-mobile">
        <PSNavBar
          openAsideBar={openAsideBar}
          closeAsideBar={closeAsideBar}
          onOpenModalUpdateData={onOpenModalUpdateData}
          disabledSignOut={disabledSignOut}
        />
      </div>
      {showBodyContainer && (
        <>
          <BodyContainer expandBar={expandBar} className="columns is-desktop is-mobile">
            <div className="columns body-left ">
              <PSAsideBar
                attributesCore={attributesCore || null}
                onCloseBar={onClose}
                onOpenModalUpdateData={onOpenModalUpdateData}
              />
            </div>
            <div className="columns body-right">{childrenWithProps}</div>
          </BodyContainer>
          <PSChatBoot />
          <PSFooterPageIntranet />
        </>
      )}
      {isHome && showModalCheck && (
        <PSModalCheckPromotions
          loading={loading}
          openModal={showModalCheck}
          center={false}
          onClose={() => setShowModalCheck(false)}
          onClick={onClickAcceptPromotions}
        />
      )}

      {showModalUpdateData && (
        <Modal
          animationDuration={0}
          open={showModalUpdateData}
          onClose={() => {}}
          showCloseIcon={false}
          onOverlayClick={() => {}}
        >
          <PSMessageResponse icon="failed" onClose={() => setShowModalUpdateData(false)}>
            <p>La información ingresada no se guardará. ¿Estás seguro de abandonar la página?</p>
            <div className="m-1">
              <PSButton onClick={onCloseModalUpdateData}>Aceptar</PSButton>
            </div>
          </PSMessageResponse>
        </Modal>
      )}

      {showModalSurvey &&
        userInfo &&
        (!userInfo.attributes?.lastSurvey || userInfo.attributes?.lastSurvey === '-1') && (
          <PSModalSatisfactionSurvey
            onSelectValue={(value) => {
              saveSurvey(value);
              const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
              ga.pageview(window.location.pathname + window.location.search);
              ga.event({
                category: `${messageTypeUser}Encuesta de satisfacción`,
                action: 'click',
                label: `El usuario calificó la encuesta de satisfacción`,
                value: 1,
              });
            }}
            valueSelected={valueSelected}
            openModal={showModalSurvey}
            onClose={saveSurvey}
          />
        )}

      {showSessionExpired && (
        <PSModalErrorResponse
          icon="face"
          title="Tu sesión ha expirado"
          message="Vuelve a iniciar sesión"
          buttonText="Aceptar"
          onClick={redirectToLogin}
        />
      )}
      {hasErrorPage && !showSessionExpired && (
        <PSModalErrorResponse
          title={errorPage.title}
          message={errorPage.message}
          buttonText={errorPage.buttonText}
          icon={errorPage.icon}
          onClick={acceptError}
        />
      )}
    </PageContainer>
  );
}

export const withHome = (Component) => (props) => {
  return (
    <GAConsumer>
      {(ga) => (
        <PSHome>
          <Component {...props} ga={ga} />
        </PSHome>
      )}
    </GAConsumer>
  );
};
