import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  sendVerificationCode,
  setUserForgotPassword,
  validateVerificationCode,
  setVerificationCodeStatus,
  setValidCode,
} from '../../redux/auth';
import { removeDashInput } from '../../../util/Util';
import { Timer } from '../../../util/Constant';
import { Strings } from '../../shared/constants/Strings';
import { Routes } from '../../../routes';

import { GAProvider } from '../../components/PSAnalytics';
import PSLoginContainer from '../../components/PSLoginContainer';
import PSHeaderLogin from '../../components/PSHeaderLogin';
import PSButton from '../../components/PSButton';
import PSBox from '../../components/PSBox';
import PSLabel from '../../components/PSLabel';
import PSLoginSelectValidation from '../../components/PSLoginSelectValidation';
import PSLogingMessageValidation from '../../components/PSLogingMessageValidation';
import PSTimerValidation from '../../components/PSTimerValidation';
import PSParagraph from '../../components/PSParagraph';
import PSError from '../../components/PSError';
import PSMessageResponse from '../../components/PSMessageResponse';

import './index.scss';

// TODO : It's constan is necesay by konfidi
const ID_FAD = null; 

export default function ForgotPassword({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loading,
    codeSent,
    errorCodeSent,
    forgotPassword: user,
    validCode,
  } = useSelector((state) => state.auth);

  const [validationType, setValidationType] = useState(user.validationType);
  const [sendValidation, setSendValidation] = useState(false);
  const [confirmValidation, setConfirmValidation] = useState(false);
  const [codeValidation, setCodeValidation] = useState(null);
  const [error, setError] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [validationCode, setValidationCode] = useState();
  const [exceedAttempts, setExceedAttempts] = useState();
  const [sendCode, setSendCode] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const ga = React.useContext(GAProvider);

  const onSelectValidation = (value) => {
    setValidationType(value);
    dispatch(setUserForgotPassword({ validationType: value }));
  };
  const onButtonClick = () => {
    if (confirmValidation) {
      sendCodeValidation();
    } else {
      sendToValidation();
    }
  };

  const sendToValidation = () => {
    if (!validationType) {
      setError('Seleccione una opción');
      return;
    }

    setError(null);

    if (!sendValidation) {
      dispatch(sendVerificationCode(ID_FAD , user));
    } else if (!confirmValidation) {
      setConfirmValidation(true);
    }
  };

  const sendCodeValidation = () => {
    if (!removeDashInput(codeValidation)) {
      setError('Ingrese el codigo');
    }

    setError(null);
    dispatch(validateVerificationCode(removeDashInput(codeValidation), ID_FAD , user));
  };

  const forwardCode = () => {
    setSendCode(true);
    setDisabledButton(false);
    dispatch(setVerificationCodeStatus(false));
    dispatch(sendVerificationCode(ID_FAD ,user));
  };

  const getFooterMenssage = () => {
    if (confirmValidation) {
      return '';
    }

    if (sendValidation) {
      return validationType === 'phoneNumber' ? Strings.INFO_PHONE : Strings.INFO_EMAIL;
    }

    return Strings.INFO_PASSWORD;
  };

  const onClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (codeSent) {
      setSendValidation(true);
      const typeUser = user.documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';
      const labelGA = `El usuario solicitó el envío de la clave OTP${
        validationType === 'phoneNumber' ? ' por celular' : ' por email'
      }`;

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${typeUser}Recover Password`,
        action: 'click',
        label: labelGA,
        value: 1,
      });
    }
  }, [codeSent]);

  useEffect(() => {
    if (validCode === 'TRIES_EXCEEDED') {
      setExceedAttempts(true);
    }

    if (validCode === 'INVALID_CODE') {
      setInvalidCode(true);
      const documentType = user.documentType.toString();
      const typeUser = documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';

      const labelGA = `El usuario introdujo una clave OTP errónea enviada${
        validationType === 'phoneNumber' ? ' por celular' : ' por email'
      }`;

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${typeUser}Recover Password`,
        action: 'click',
        label: labelGA,
        value: 1,
      });
    }

    if (validCode === 'VALID_CODE' && codeValidation) {
      const documentType = user.documentType.toString();
      const typeUser = documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';

      const labelGA = `El usuario validó la clave OTP correctamente enviada${
        validationType === 'phoneNumber' ? ' por celular' : ' por email'
      }`;

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${typeUser}Recover Password`,
        action: 'click',
        label: labelGA,
        value: 1,
      });

      dispatch(setUserForgotPassword({ code: removeDashInput(codeValidation) }));
      setValidationCode(true);
      setTimeout(() => {
        history.push(Routes.FORGOT_PASSWORD_CREATE_PASSWORD);
      }, Timer.waitingApi);
    }
  }, [validCode]);

  return (
    <div className="forgot-password-container">
      {!validationCode && !exceedAttempts && !errorCodeSent && (
        <PSLoginContainer className="password-container" onClose={onClose}>
          <PSHeaderLogin
            className="forgot-password-header"
            title={
              !sendValidation || !confirmValidation
                ? 'Recuperar tu Contraseña'
                : 'Solicitud de recuperación de contraseña'
            }
            icon="home"
          />

          <div className="password-body-validation">
            {!sendValidation && (
              <PSLoginSelectValidation
                className="password-select-validation"
                onChange={onSelectValidation}
                value={validationType}
              />
            )}

            {sendValidation && !confirmValidation && (
              <PSLogingMessageValidation
                className="password-message-validation"
                type={validationType}
              />
            )}

            {confirmValidation && (
              <PSTimerValidation
                className="password-timer-validation"
                onChange={(e) => {
                  setInvalidCode(false);
                  setCodeValidation(e.target.value);
                  dispatch(setValidCode(null));
                }}
                value={codeValidation}
                setDisabledProp={(value) => setDisabledButton(value)}
                refresh={sendCode}
                error={confirmValidation && invalidCode}
              />
            )}

            {error && <PSError>{error}</PSError>}

            {confirmValidation && invalidCode && (
              <PSError>
                *El código de verificación que ingresaste no es correcto vuelve a solicitar el envío
              </PSError>
            )}
          </div>
          <PSBox className="footer-container">
            <PSButton
              onClick={onButtonClick}
              disabled={
                confirmValidation ? codeValidation == null || codeValidation === '' : disabledButton
              }
              className={disabledButton || validationType === undefined ? 'button-disabled' : ''}
              loading={!sendCode && loading}
            >
              Aceptar
            </PSButton>
          </PSBox>

          {confirmValidation && (
            <PSBox className="footer-resend-container">
              <PSLabel className="password-footer-validation">
                ¿No recibiste el código de verificación?
              </PSLabel>
              <PSButton
                className="btn-secondary"
                onClick={forwardCode}
                loading={sendCode && loading}
              >
                Reenviar
              </PSButton>
            </PSBox>
          )}
          <PSBox className="password-footer-message">
            <PSParagraph>{getFooterMenssage()}</PSParagraph>
          </PSBox>
        </PSLoginContainer>
      )}
      {validationCode && <PSMessageResponse title="Validación exitosa" icon="successFace" />}

      {exceedAttempts && (
        <PSMessageResponse
          onClose={onClose}
          title={Strings.PASSWORD_EXCEEDED_TRY_TITlE}
          icon="failed"
        >
          {Strings.PASSWORD_EXCEEDED_TRY}
        </PSMessageResponse>
      )}

      {errorCodeSent && (
        <PSMessageResponse onClose={onClose} title={Strings.SEND_EXCEEDED_TRY_TITLE} icon="failed">
          {Strings.SEND_EXCEEDED_TRY}
        </PSMessageResponse>
      )}
    </div>
  );
}
