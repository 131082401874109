import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  createPaymentError,
  STATUS_PAYMENT_DEFAULT,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
} from '../../redux/auth/actions/payment-validation';
import { createErrorPage } from '../../redux/auth';
import { setStateProduct } from '../../redux/auth/actions/state-product';
import { policyFilterMessage } from '../../shared/constants/Strings';
import { getOptionsToProduct, dateNowFormatted, fixStructBase64 } from '../../../util/Util';
import { Coverage } from '../../shared/constants/Coverage';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Routes } from '../../../routes';
import GenericConstancyServices from '../../shared/services/GenericConstancyServices';

import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';

const img = 'product-VidaCreditoFacil';
const imgMobile = 'product-VidaCreditoFacil-mobile';

const headerTableFather = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

export default function HomeProductLifeCreditEasy(props) {
  const [loadingOption, setLoadingOption] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userInfo, errorPayment } = useSelector((state) => state.auth);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [currentCode, setCurrentCode] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
  const ga = React.useContext(GAProvider);
  const {
    policies = [],
    menuId = '',
    menuDescription = '',
    policy: policyUpdated = null,
  } = location.state; 
  const policiesMessage = policyFilterMessage.vidaDevolucion;

  const [fileBase64, setFileBase64] = useState({});
  const [options, setOptions] = useState([]);

  const getOptionsAvailable = () => {
    const optionList = [];

    if (process.env.REACT_APP_PERMISSION_PRODUCT_VCF_HISTORIAL_CAMBIO_TARJETA === 'true') {
      optionList.push('HCT');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_VCF_MODIFICACION_TARJETA === 'true') {
      optionList.push('MDT');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_VCF_DESCARGA_POLIZA === 'true') {
      optionList.push('DP');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_VCF_DESCARGA_COMPROBANTE_PAGO === 'true') {
      optionList.push('DCP');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_VCF_CONSTANCIA_ASEGURADO === 'true') {
      optionList.push('CDA');
    }
    setOptions(optionList);
  };

  const getComprobantePago = () => {
    const { nbranch, idProduct, nroPolicy, nroCertificate } = policies[currentIndex];
    const data = {
      'branch-id': parseInt(nbranch),
      'product-id': parseInt(idProduct),
      policy: nroPolicy,
      certificate: nroCertificate,
    };
    setParamsComprobantes(data);
  };

  useEffect(() => {
    switch (errorPayment) {
      case STATUS_PAYMENT_DEFAULT:
        return;
      case STATUS_PAYMENT_FAILED:
        dispatch(
          createErrorPage({
            state: 'true',
            icon: 'face',
            title: 'Error',
            message: 'No se pudo procesar su actualización de tarjeta',
            onAction: () => {},
          })
        );
        dispatch(createPaymentError(STATUS_PAYMENT_DEFAULT));
        break;
      case STATUS_PAYMENT_SUCCESS:
        dispatch(
          createErrorPage({
            state: 'true',
            icon: 'successFace',
            title: '¡Tarjeta actualizada!',
            message: 'Tu tarjeta fue actualizada correctamente.',
            onAction: () => {},
          })
        );
        dispatch(createPaymentError(STATUS_PAYMENT_DEFAULT));
        break;
      default:
    }
  }, [errorPayment]);

  useEffect(() => {
    if (errorPayment === STATUS_PAYMENT_DEFAULT) {
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${menuDescription}`,
        action: 'click',
        label: `El usuario visitó la página de ${menuDescription}`,
        value: 1,
      });
    }
  }, [ga]);

  useEffect(() => {
    if (location.state) {
      dispatch(setStateProduct(location.state));
    }
  }, [location.state]);

  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];
      getOptionsAvailable();
      if (currentCode === 'DCP') {
        getComprobantePago();
      }
      if (currentCode === 'CDA') {
        getConstanciaUserNatural(policyCurrent);
      }
    }
  }, [currentIndex, currentCode]);

  const getConstanciaUserNatural = async (policyCurrent) => {
    try {
      setLoadingOption(true);
      const { attributes: user, documentType, document: documentNumber } = userInfo;
      const dateFormatted = dateNowFormatted();
      const params = {
        data: {
          arrayBoletas: [
            {
              documentType,
              clientName: user?.legalName || '',
              documentNumber,
              initValidity: policyCurrent?.dateStart,
              finishValidity: policyCurrent?.dateEnd,
              policy: policyCurrent?.nroPolicy.toString().padStart(10, '0'),
              policyType: `Seguro de Vida Crédito Fácil`,
              ammount: 'Según condiciones de la Póliza',
              dateDescription: `Surquillo, ${dateFormatted}`,
            },
          ],
          enviarEmail: false,
        },
      };
      const headers = { 'segment-id': menuId };
      const { data, status } = await GenericConstancyServices.responseDocumentUserNatural(
        params,
        headers
      );

      if (data && status === 200) {
        const fixBase64 = fixStructBase64(data.document);
        setFileBase64.CDA = fixBase64;
      } else {
        setFileBase64.CDA = null;
      }
    } catch (error) {
      setFileBase64.CDA = null;
      dispatch(createErrorPage());
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };

  return (
    <div className="home-life-container">
      <PSBodyProduct
        questions={FrequentQuestions.SEGURO_DESGRAVAMEN}
        coverages={Coverage.SEGURO_DESGRAVAMEN}
        img={img}
        imgMobile={imgMobile}
        descriptionHeader={menuDescription}
        iconHeader="productVDP"
        loading={loading}
        showMostConsulted={false}
        data={policies}
        options={getOptionsToProduct(options)}
        headerTable={headerTableFather}
        goBackFunction={() => history.push(Routes.HOME)}
        policyUpdated={policyUpdated}
        onSelectItem={(item) => setCurrentIndex(item)}
        onSetCurrentCodeForm={setCurrentCode}
        paramsComprobantes={paramsComprobantes}
        menuId={menuId}
        useDigitalPolicy
        policiesMessage={policiesMessage}
        fileBase64={fileBase64}
        loadingOption={loadingOption}
        {...props}
      />
    </div>
  );
}
