import isEmpty from "loadsh/isEmpty";
import { MINIMUN_REQUIREMENTS } from "./constant";

const getRam = () => navigator.deviceMemory || 0;
const getUserAgent = () => navigator.userAgent || navigator.vendor || window.opera;
const getParams =  (documentType , documentNumber , validationPercentage) => {
    let params =  new URLSearchParams({
        documentType,
        documentNumber ,
        validationPercentage,
    })
    return params.toString(); 
}

const setFadError = (dni, errorCount) => {
    //TODO: change for api 
    const errors = JSON.parse(sessionStorage.getItem('fadError')) || {};
    errors[dni] = errorCount;
    sessionStorage.setItem('fadError', JSON.stringify(errors));
};
const getVersion = (device , userAgent) => {
    const regex = new RegExp(`${device} (\\d+)(?:\\.(\\d+))?`, 'i');
    const match = userAgent.match(regex);
    const version = match ? parseFloat(`${match[1]}.${match[2] || 0}`) : null;
    return version
}

const checkDeviceSpecifications = () => {
    const ram = getRam()
    const userAgent = getUserAgent()
    if (!isEmpty(userAgent)) {
        let webversion=true
        if (/android/i.test(userAgent)) {
            const version = getVersion("Android", userAgent)
            return version !== null && version >= MINIMUN_REQUIREMENTS.ANDROID && ram >= MINIMUN_REQUIREMENTS.RAM
        }
        // Verificar si es un dispositivo iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            const version = getVersion("OS", userAgent)
            return version !== null && version >= MINIMUN_REQUIREMENTS.IOS 
        } else return webversion && ram >= MINIMUN_REQUIREMENTS.RAM
    } return null
}

export { setFadError , checkDeviceSpecifications ,getParams  }