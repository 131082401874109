import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { policyFilterMessage } from '../../shared/constants/Strings';
import { getOptionsToProduct } from '../../../util/Util';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Coverage } from '../../shared/constants/Coverage';
import GetSoatCertificate from '../../shared/services/GetSoatCertificate';
import PaymentVouchersServices from '../../shared/services/PaymentVouchersServices';

import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';

const headerTableFather = [
  { elementProp: 'nroPlaca', headerColumn: 'Placas' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
  { elementProp: 'marca', headerColumn: 'Marca' },
  { elementProp: 'modelo', headerColumn: 'Modelo' },
];

const img = 'product-soat';
const imgMobile = 'product-soat-mobile';

const getOptionsAvailable = () => {
  const options = [];
  if (process.env.REACT_APP_PERMISSION_PRODUCT_SOAT_DESCARGA_CERTIFICADO_DIGITAL === 'true') {
    options.push('DCD');
  }
  if (process.env.REACT_APP_PERMISSION_PRODUCT_SOAT_DESCARGA_COMPROBANTE_PAGO === 'true') {
    options.push('DCP');
  }
  return options;
};

export default function HomeServices(props) {
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const [fileBase64, setFileBase64] = useState({});
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCode, setCurrentCode] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const location = useLocation();
  const { policies } = location.state;
  const ga = React.useContext(GAProvider);
  const options = getOptionsAvailable();
  const urlToProduct =
    'https://plataformadigital.protectasecurity.pe/ecommerce/soat/step1?utm_source=intranet&utm_medium=banner&utm_campaign=soat';

  const dispatch = useDispatch();
  const policiesMessage = policyFilterMessage.soat;

  const getCertificate = async () => {
    try {
      setLoadingOption(true);
      const response = await GetSoatCertificate.responseGetSoatKey();
      if (response.statusCode === 200) {
        const keyFile = response.data.key;

        if (keyFile !== null) {
          const { nroPolicy } = policies[currentIndex];
          const responseFile = await GetSoatCertificate.responseGetSoatConstancia(
            `Bearer ${keyFile}`,
            nroPolicy
          );
          setFileBase64.DCD = responseFile.file;
          setFileBase64({ ...setFileBase64 });
          setLoadingOption(false);
        } else {
          setLoadingOption(false);
        }
      } else {
        setLoadingOption(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingOption(false);
      // dispatch(createErrorPage());
    }
  };

  const getComprobantePago = () => {
    const { nbranch, idProduct, nroPolicy, nroCertificate } = policies[currentIndex];
    const data = {
      'branch-id': parseInt(nbranch),
      'product-id': parseInt(idProduct),
      policy: nroPolicy,
      certificate: nroCertificate,
    };
    setParamsComprobantes(data);
  };

  useEffect(() => {
    if (currentIndex !== -1) {
      if (currentCode === 'DCD') {
        getCertificate();
      }
      if (currentCode === 'DCP') {
        getComprobantePago();
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto SOAT`,
      action: 'click',
      label: 'El usuario visitó la página SOAT',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="home-soat-container">
      <PSBodyProduct
        questions={FrequentQuestions.SOAT}
        coverages={Coverage.SOAT}
        paramsComprobantes={paramsComprobantes}
        img={img}
        imgMobile={imgMobile}
        descriptionHeader="SOAT"
        iconHeader="soat"
        loading={loading}
        data={policies}
        options={getOptionsToProduct(options)}
        fileBase64={fileBase64}
        headerTable={headerTableFather}
        onSelectItem={(item) => setCurrentIndex(item)}
        urlToProduct={urlToProduct}
        textButtonToProduct="¡Renueva aquí!"
        withLinkToProduct={false}
        loadingOption={loadingOption}
        onSetCurrentCodeForm={setCurrentCode}
        policiesMessage={policiesMessage}
        {...props}
      />
    </div>
  );
}
