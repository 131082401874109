import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createErrorPage } from '../../redux/auth';

import { policyFilterMessage } from '../../shared/constants/Strings';
import { fixStructBase64, getOptionsToProduct, dateNowFormatted } from '../../../util/Util';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Coverage } from '../../shared/constants/Coverage';
import GenericConstancyServices from '../../shared/services/GenericConstancyServices';

import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';
import { PSInfoProblems } from '../../components/molecules';

import './index.scss';

const img = 'product-VidaGrupo';
const imgMobile = 'product-VidaGrupo-mobile';

const headerTableFather = [
  { elementProp: 'shortDescription', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'nroCertificate', headerColumn: 'Número de certificado' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Desde' },
];

export default function HomeProductVidaIndividual(props) {
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const [options, setOptions] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCode, setCurrentCode] = useState(null);
  const [fileBase64, setFileBase64] = useState({});
  const location = useLocation();
  const { menuId, policies } = location.state;
  const dispatch = useDispatch();
  const policiesMessage = policyFilterMessage.vidaIndividual;

  const getOptionsAvailable = (rol = 'Asegurado', nbranch) => {
    const option = [];
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_INDIVIDUAL_CONSTANCIA_ASEGURADO === 'true' &&
      rol.trim() !== 'Contratante'
    ) {
      option.push('CDA');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_ASEGURADOS === 'true' && isBusiness) {
      option.push('CDLA');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_ASEGURADOS === 'true' && isBusiness) {
      option.push('CICA');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_PRODUCTO_DIGITAL === 'true') {
      option.push('DCDVI');
    }
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_INDIVIDUAL_DESCARGA_COMPROBANTE_PAGO === 'true'
    ) {
      option.push('DCP');
    }
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_INDIVIDUAL_DESCARGA_POLIZA === 'true' &&
      nbranch !== '72' // belcorp
    ) {
      option.push('DP');
    }
    setOptions(option);
  };

  const getComprobantePago = () => {
    if (currentIndex !== -1) {
      const { nbranch, idProduct, nroPolicy, nroCertificate } = policies[currentIndex];
      const data = {
        'branch-id': parseInt(nbranch),
        'product-id': parseInt(idProduct),
        policy: nroPolicy,
        certificate: nroCertificate,
      };
      setParamsComprobantes(data);
    }
  };

  const getConstanciaUserNatural = async (policyCurrent) => {
    try {
      setLoadingOption(true);
      const { attributes: user, documentType, document: documentNumber } = userInfo;
      const dateFormatted = dateNowFormatted();
      const params = {
        data: {
          arrayBoletas: [
            {
              documentType,
              clientName: user?.legalName || '',
              documentNumber,
              initValidity: policyCurrent?.dateStart,
              finishValidity: policyCurrent?.dateEnd,
              policy: policyCurrent?.nroPolicy.toString().padStart(10, '0'),
              policyType: `Seguro de Vida Individual de Corto Plazo`,
              ammount: 'Según condiciones de la Póliza',
              dateDescription: `Surquillo, ${dateFormatted}`,
            },
          ],
          enviarEmail: false,
        },
      };
      const headers = { 'segment-id': menuId };
      const { data, status } = await GenericConstancyServices.responseDocumentUserNatural(
        params,
        headers
      );

      if (data && status === 200) {
        const fixBase64 = fixStructBase64(data.document);
        setFileBase64.CDA = fixBase64;
      } else {
        setFileBase64.CDA = null;
      }
    } catch (error) {
      setFileBase64.CDA = null;
      dispatch(createErrorPage());
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };

  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];
      getOptionsAvailable(policyCurrent.rol, policyCurrent.nbranch);
      if (currentCode === 'CDA') {
        getConstanciaUserNatural(policyCurrent);
      }
      if (currentCode === 'DCP') {
        getComprobantePago();
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto Vida Individual de Corto Plazo`,
      action: 'click',
      label: 'El usuario visitó la página de Vida Individual de Corto Plazo',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="home-vidaIndividual-container">
      {process.env.REACT_APP_NO_AVAILABLE_PRODUCTOS_VIDA_INDIVIDUAL === 'true' ? (
        <PSInfoProblems />
      ) : (
        <PSBodyProduct
          questions={FrequentQuestions.VIDA_LEY}
          coverages={Coverage.VIDA_LEY}
          paramsComprobantes={paramsComprobantes}
          img={img}
          imgMobile={imgMobile}
          descriptionHeader="Vida Individual de Corto Plazo"
          iconHeader="bag"
          loading={loading}
          options={getOptionsToProduct(options)}
          fileBase64={fileBase64}
          data={policies}
          headerTable={headerTableFather}
          showMostConsulted={false}
          onSelectItem={(item) => setCurrentIndex(item)}
          onSetCurrentCodeForm={setCurrentCode}
          loadingOption={loadingOption}
          policiesMessage={policiesMessage}
          useDigitalPolicy
          menuId={menuId}
          {...props}
        />
      )}
    </div>
  );
}
