import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createErrorPage } from '../../redux/auth';

import { policyFilterMessage } from '../../shared/constants/Strings';
import { fixStructBase64, getOptionsToProduct, dateNowFormatted } from '../../../util/Util';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Coverage } from '../../shared/constants/Coverage';
import GenericConstancyServices from '../../shared/services/GenericConstancyServices';

import { RentaT } from '../../shared/model/RentaT';
import { GAProvider } from '../../components/PSAnalytics';
import PSBodyProduct from '../../components/PSBodyProduct';

import './index.scss';

const img = 'product-AccidentesPersonales';
const imgMobile = 'product-AccidentesPersonales-mobile';

const headerTableFather = [
  { elementProp: 'shortDescription', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'nroCertificate', headerColumn: 'Número de certificado' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

export default function HomeProductAccidentesPersonales(props) {
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const ga = React.useContext(GAProvider);
  const [options, setOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCode, setCurrentCode] = useState(null);
  const [fileBase64, setFileBase64] = useState({});
  const location = useLocation();
  const { menuId, policies } = location.state;
  const dispatch = useDispatch();
  const policiesMessage = policyFilterMessage.accidentesPersonales;

  const getOptionsAvailable = (rol = 'Asegurado') => {
    const option = [];
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_ACCIDENTES_PERSONALES_CONSTANCIA_ASEGURADOS ===
        'true' &&
      !isBusiness &&
      rol.trim() !== 'Contratante'
    ) {
      option.push('CDA');
    }
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_ACCIDENTES_PERSONALES_GEOLOCALIZACION_CLINICAS ===
      'true'
    ) {
      option.push('GLC');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_ASEGURADOS === 'true' && isBusiness) {
      option.push('CDLA');
    }
    if (process.env.REACT_APP_PERMISSION_SHOW_ASEGURADOS === 'true' && isBusiness) {
      option.push('CICA');
    }
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_ACCIDENTES_PERSONALES_DESCARGA_COMPROBANTE_PAGO ===
      'true'
    ) {
      option.push('DCP');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_ACCIDENTES_PERSONALES_DESCARGA_POLIZA === 'true') {
      option.push('DP');
    }
    setOptions(option);
  };

  const getComprobantePago = () => {
    if (currentIndex !== -1) {
      const { nbranch, idProduct, nroPolicy, nroCertificate } = policies[currentIndex];
      const data = {
        'branch-id': parseInt(nbranch),
        'product-id': parseInt(idProduct),
        policy: nroPolicy,
        certificate: nroCertificate,
      };
      setParamsComprobantes(data);
    }
  };

  const getConstanciaUserNatural = async (policyCurrent) => {
    try {
      setLoadingOption(true);
      const { attributes: user, documentType, document: documentNumber } = userInfo;
      const dateFormatted = dateNowFormatted();
      const params = {
        data: {
          arrayBoletas: [
            {
              documentType,
              clientName: user?.legalName || '',
              documentNumber,
              initValidity: policyCurrent?.dateStart,
              finishValidity: policyCurrent?.dateEnd,
              policy: policyCurrent?.nroPolicy.toString().padStart(10, '0'),
              policyType: `Seguro de ${policyCurrent?.producto.trim()}`,
              ammount: 'Según condiciones de la Póliza',
              dateDescription: `Surquillo, ${dateFormatted}`,
            },
          ],
          enviarEmail: false,
        },
      };
      const headers = { 'segment-id': menuId };
      const { data, status } = await GenericConstancyServices.responseDocumentUserNatural(
        params,
        headers
      );
      if (data && status === 200) {
        const fixBase64 = fixStructBase64(data.document);
        setFileBase64.CDA = fixBase64;
      } else {
        setFileBase64.CDA = null;
      }
    } catch (error) {
      setFileBase64.CDA = null;
      dispatch(createErrorPage());
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };

  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];
      getOptionsAvailable(policyCurrent.rol);

      if (currentCode === 'CDA') {
        getConstanciaUserNatural(policyCurrent);
      }
      if (currentCode === 'DCP') {
        getComprobantePago();
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto Accidentes Personales`,
      action: 'click',
      label: 'El usuario visitó la página de Accidentes Personales',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="home-accidentesPersonales-container">
      <PSBodyProduct
        questions={FrequentQuestions.SEGURO_ACCIDENTES}
        coverages={Coverage.ACCIDENTES_PERSONALES}
        paramsComprobantes={paramsComprobantes}
        img={img}
        imgMobile={imgMobile}
        hasConstanciaAsegurados="true"
        hasGeolocalizacionClinicas="true"
        descriptionHeader="Accidentes Personales"
        iconHeader="personalAccident"
        loading={loading}
        options={getOptionsToProduct(options)}
        fileBase64={fileBase64}
        data={policies}
        headerTable={headerTableFather}
        onSelectItem={(item) => setCurrentIndex(item)}
        onSetCurrentCodeForm={setCurrentCode}
        loadingOption={loadingOption}
        policiesMessage={policiesMessage}
        useDigitalPolicy
        menuId={menuId}
        {...props}
      />
    </div>
  );
}
